.body_cgu {
  .public_left_bloc {
    width: 100% !important;
    height: auto !important;
  }
  
  .cgu_content {
    height: 340px;
    overflow: auto;
    margin-bottom: 10px;
    & p, ul{
      padding-right: 8px;
    }
  }
  
  .accept-cgu {
    z-index: 1;
    text-align: right;
  }

  #btn-scrolldown {
    width: 40px;
    padding: 0;
  }
  
  .wrapper {
    z-index: 3;
    position: relative;
    cursor: not-allowed;
  }
  
  .efacture-alert, .internetAccount-alert{
    padding: 15px;
  }
  
  button.btn-disabled-cgu {
    z-index: 5;
    pointer-events: none;
    color: darkgrey !important;
  }

  #btn-cgu {
    width: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .disabled.btn, [disabled].btn, fieldset[disabled].btn {
    opacity: 1;
  }
  
  i#loading-icon {
    color: #000;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    font-size: 100px;
    margin-top: -50px;
    margin-left: -50px;
  }

  @-moz-keyframes pulse {
    0% {-moz-transform: scale(1.1)}
     50% {-moz-transform: scale(0.8)}
     100% {-moz-transform: scale(1.1)}
  }
  @-webkit-keyframes pulse {
    0% {-webkit-transform: scale(1)}
    50% {-webkit-transform: scale(0.8)}
    100% {-webkit-transform: scale(1)}
  }
  @-ms-keyframes pulse {
    0% {-ms-transform: scale(1.1)}
    50% {-ms-transform: scale(0.8)}
    100% {-ms-transform: scale(1.1)}
  }
  @keyframes pulse {
    0% {transform: scale(1.1)}
    50% {transform: scale(0.8)}
    100% {transform: scale(1.1)}
  }
  i#scroll-icon {
    position: absolute;
    cursor: pointer;
    right: 30px;
    bottom: 30%;
    -moz-animation: pulse 2s linear infinite;
    -webkit-animation: pulse 2s linear infinite;
    -ms-animation: pulse 2s linear infinite;
    animation: pulse 2s linear infinite;
  }

  @media screen and (max-width: 991px) {
    i#scroll-icon {
      cursor: pointer;
      right: 25px;
      bottom: 18%;
    }
  }

  #efacture-msg {
    color: #376fb3;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #efacture-checkbox:disabled{
    color: #d2d1d1;
    border: 1px solid #d2d1d1;
    &::before {
      color: #d2d1d1;
      border: 1px solid #d2d1d1;
    }
  }
}

