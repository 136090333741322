/**
 * importer.less
 *
 * By default, new Sails projects are configured to compile this file
 * from LESS to CSS.  Unlike CSS files, LESS files are not compiled and
 * included automatically unless they are imported below.
 *
 * The LESS files imported below are compiled and included in the order
 * they are listed.  Mixins, variables, etc. should be imported first
 * so that they can be accessed by subsequent LESS stylesheets.
 *
 * (Just like the rest of the asset pipeline bundled in Sails, you can
 * always omit, customize, or replace this behavior with SASS, SCSS,
 * or any other Grunt tasks you like.)
 */
/*--Menu principal--Desktops*/
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i');

// @media (min-width: 768px) {
//   .navbar {
//     border-radius: 0px;
//   }
// }

// .flex-center {
//   display: flex;
//   align-items: center;
// }

// #main-menu {
//   background-color: #fff;
//   border: none;
// }

// #main-menu li a {
//   display: block;
//   color: #9d9d9d;
// }

// @media screen and (max-width: 767px) {
//   #main-menu li a .glyphicon,
//   #main-menu li a .fa,
//   #main-menu li a .flag-icon {
//     margin-left: 15px;
//     margin-right: 10px;
//     max-width: 1em;
//   }
// }

// #main-menu #left-navbar.nav {
//   margin-left: 40px;
// }

// @media screen and (max-width: 1199px) {
//   #main-menu #left-navbar.nav {
//     margin-left: 20px;
//   }
// }

// @media screen and (max-width: 991px) {
//   #main-menu #left-navbar.nav {
//     margin-left: 0px;
//   }
// }

// #main-menu #left-navbar.nav li > a {
//   font-size: 18px;
//   font-weight: 600;
//   border-bottom: 5px solid transparent;
//   padding: 18px 25px;
// }

// @media screen and (max-width: 1199px) {
//   #main-menu #left-navbar.nav li > a {
//     padding: 18px 16px;
//   }
// }

// @media screen and (max-width: 991px) {
//   #main-menu #left-navbar.nav li > a {
//     font-size: 14px;
//     padding: 18px 8px;
//   }
// }

// #main-menu #left-navbar.nav li.active a,
// #main-menu #left-navbar.nav li a:hover,
// #main-menu #left-navbar.nav .open > a {
//   border-bottom: 5px solid $text-primary;
//   background-color: transparent;
// }

// #main-menu .dropdown-toggle {
//   padding: 8px;
//   margin-right: 8px;
// }

// #main-menu .dropdown-menu,
// #main-menu .dropdown-menu ul,
// #main-menu .dropdown-menu li,
// #main-menu .dropdown-menu a,
// #main-menu .visible-xs,
// #main-menu .visible-xs ul,
// #main-menu .visible-xs li,
// #main-menu .visible-xs a,
// #main-menu .navbar-collapse {
//   margin: 0;
//   padding: 0;
//   text-decoration: none;
//   list-style: none;
// }

// #main-menu .dropdown-menu,
// #main-menu .visible-xs {
//   min-width: 200px;
// }

// @media screen and (max-width: 767px) {
//   #main-menu .dropdown-menu li:first-child,
//   #main-menu .visible-xs li:first-child {
//     padding-top: 20px;
//   }

//   #main-menu .dropdown-menu li:last-child,
//   #main-menu .visible-xs li:last-child {
//     padding-top: 20px;
//   }

//   #main-menu .dropdown-menu li a:last-child,
//   #main-menu .visible-xs li a:last-child {
//     padding-bottom: 20px;
//   }

//   #main-menu .dropdown-menu a,
//   #main-menu .visible-xs a {
//     color: #777;
//     padding-top: 8px;
//     padding-right: 12px;
//     padding-left: 12px;
//     padding-bottom: 10px;
//     font-size: 16px;
//   }
// }

// #main-menu .dropdown-menu a,
// #main-menu .visible-xs a {
//   padding: 8px;
//   padding-right: 12px;
//   padding-left: 12px;
// }

// #main-menu .dropdown-menu li.active a,
// #main-menu .visible-xs li.active a,
// #main-menu .dropdown-menu li a:hover,
// #main-menu .visible-xs li a:hover {
//   font-weight: normal;
// }

// #main-menu .dropdown-menu ul.sub-menu,
// #main-menu .visible-xs ul.sub-menu {
//   background-color: #fafdfe;
//   box-shadow: inset 0 10px 10px -12px #90c5f6;
//   position: relative;
//   width: 100%;
// }

// #main-menu .dropdown-menu ul.sub-menu li a,
// #main-menu .visible-xs ul.sub-menu li a {
//   padding-left: 20%;
// }

// #main-menu .dropdown-menu .title,
// #main-menu .visible-xs .title {
//   font-size: 1.1em;
//   font-weight: 700;
//   padding: 20px;
//   text-align: center;
//   color: #777777;
// }

// #main-menu .dropdown-menu .title i,
// #main-menu .visible-xs .title i {
//   font-size: 1.5em;
//   vertical-align: bottom;
// }

// #main-menu .dropdown-menu .divider,
// #main-menu .visible-xs .divider {
//   width: 100%;
//   height: 1px;
//   background-color: #e5e5e5;
// }

// #main-menu button.navbar-toggle {
//   float: left;
//   border: none;
// }

// #main-menu button.navbar-toggle:hover,
// #main-menu button.navbar-toggle:focus {
//   background-color: transparent;
// }

// #main-menu button.navbar-toggle .icon-bar {
//   background-color: #777777;
// }

// #main-menu #menu_contract {
//   height: 45px;
//   display: flex;
//   align-items: center;
// }

// #main-menu .contract_content {
//   display: flex;
//   align-items: center;
// }

// #main-menu .contract_content .contract_icon {
//   font-size: 2.5em;
// }

// #main-menu .contract_content .contract_info {
//   padding-right: 10px;
//   padding-left: 10px;
//   font-size: 12px;
//   line-height: 12px;
//   width: 90%;
// }

// #main-menu .contract_content .contract_info .address {
//   max-width: 160px;
//   overflow: hidden;
//   white-space: nowrap;
//   text-overflow: ellipsis;
//   display: inline-block;
// }

// #main-menu .contract_content .contract_info .name {
//   padding-right: 10px;
// }

// #main-menu .contract_content .contract_info .status {
//   position: relative;
//   top: -2px;
// }

// #main-menu .contract_content .contract_info .text-right .status {
//   top: 0px;
// }

// #main-menu .contract_list,
// #main-menu .contract_search {
//   max-height: 200px;
//   overflow-y: scroll;
//   overflow-x: hidden;
// }

// #main-menu .contract_list .status,
// #main-menu .contract_search .status {
//   position: relative;
//   top: -5px;
// }

// #main-menu .contract_list li.selected,
// #main-menu .contract_search li.selected {
//   border-right: green solid 5px;
// }

// #main-menu .contract_list .label,
// #main-menu .contract_search .label {
//   font-size: 8px;
//   padding-top: 3px;
//   vertical-align: bottom;
// }

// #right-navbar .lang {
//   top: 8px;
// }

// #right-navbar .lang .dropdown-toggle {
//   font-size: 1.2em;
// }

// #right-navbar .lang .sub-menu {
//   z-index: 10;
//   list-style: none;
//   display: block;
// }

// #right-navbar .languages {
//   text-align: center;
// }

// #right-navbar .languages a {
//   display: inline-block;
//   vertical-align: top;
// }

// #right-navbar .languages img {
//   width: 20px;
// }

// @media screen and (max-width: 1299px) and (min-width: 992px) {
//   #main-menu.navbar #left-navbar a {
//     padding-right: 12px;
//     padding-left: 12px;
//   }

//   #main-menu.navbar .contract_content .contract_info .address {
//     max-width: 140px;
//   }
// }

// /* Landscape phones and portrait tablets */
// @media (max-width: 767px) {
//   #main-menu.navbar .navbar-header {
//     position: fixed;
//     z-index: 10;
//     top: 0;
//     left: 0;
//     width: 100vw;
//     margin: 0;
//     background-color: #fafdfe;
//     box-shadow: 0 0 7px 3px grey;
//   }

//   body > nav + .container {
//     margin-top: -10px;
//   }

//   #main-menu .navbar-collapse {
//     font-size: 14px;
//     position: fixed;
//     z-index: 9;
//     top: 0;
//     left: 0;
//     width: 100%;
//     max-height: 100%;
//     padding-top: 50px;
//     border: 1px solid rgba(0, 0, 0, 0.15);
//     background-color: #fff;
//     box-shadow: 0 0 6px 12px rgba(0, 0, 0, 0.175);
//     /*overflow: scroll;*/
//   }

//   #main-menu .navbar-brand {
//     position: absolute;
//     left: 50%;
//     transform: translate(-50%);
//   }
// }

/*
This file is loaded after all others CSS and can overload default display
*/
/*--Menu principal*/
/**********************************************/
.color-1 {
  color: #0090b2;
}

.color-2 {
  color: $text-secondary;
}

.color-3 {
  color: $text-primary;
}

.color-4 {
  color: #5cb85c;
}

.color-5 {
  color: white;
}

.color-6 {
  color: #fafdfe;
}

/* body {
  background-image: url('/specific/background/default_bg.jpg');
  background-color: #ebe8e4;
  font-family: 'Open Sans', sans-serif;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
} */

#logo_brand_header_public {
  background-image: url('/specififc/logo/logo.png');
  background-size: auto 60px;
  height: 60px;
  width: 205px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
}

#logo_brand_header_menu {
  // background-image: url('../../images/logo_square.png');
  background-size: 50px;
  height: 60px;
  width: 90px;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  background-position: 0px 0px;
}

.ael-link {
  background-image: url('/specific/e-smeg.png');
  background-size: 90px auto;
  background-position: 55px -30px;
  background-repeat: no-repeat;
  padding-top: 25px;
  width: 100%;
  margin-bottom: 10px;
}

.survey .part .question .pickup_answer.btn.active p,
.survey .pickup_answer.btn-default.active p,
.survey .pickup_answer.btn-default.active.focus p,
.survey .pickup_answer.btn-default.active:focus p,
.survey .pickup_answer.btn-default.active:hover p,
.survey .pickup_answer.btn-default.focus:active p,
.survey .pickup_answer.btn-default:active:focus p,
.survey .pickup_answer.btn-default:active:hover p {
  color: black;
}

hr {
  border-color: #0090b2;
}

/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
  .text-xs-left {
    text-align: left;
  }

  .text-xs-right {
    text-align: right;
  }

  .text-xs-justify {
    text-align: justify;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
  .text-xs-left {
    text-align: left;
  }

  .text-xs-right {
    text-align: right;
  }

  .text-xs-justify {
    text-align: justify;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
  .text-sm-left {
    text-align: left;
  }

  .text-sm-right {
    text-align: right;
  }

  .text-sm-center {
    text-align: center;
  }

  .text-sm-justify {
    text-align: justify;
  }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .text-md-left {
    text-align: left;
  }

  .text-md-right {
    text-align: right;
  }

  .text-md-center {
    text-align: center;
  }

  .text-md-justify {
    text-align: justify;
  }
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

body.admin {
  a.btn.btn-primary{
    line-height: 34px;
  }
  .btn {
    color: #666666;
    background-size: 300% 100%;
    transition: 0.2s;
    padding: 0 15px;
    .caret {
      color: #666666;
    }
    &:hover {
      color: black;
      background-color: #e5e5e5 !important;
      .caret {
        color: black;
      }
    }
    background-color: $bg-primary !important;
    background-image: unset;
    border-radius: 30px;
  }
  .btn-primary{
    line-height: 34px;
    color: #fff;
    background-size: 300% 100%;
    background-image: linear-gradient(to right, #7D51D9, #376EB3);
    &:hover {
      color: #fff;
      background-color: #e5e5e5 !important;
      .caret {
        color: black;
      }
    }
  }
  .refresh-button{
    top: -5px;
  }
  thead.primary_bkgd{
    background-image: $gradient-bright-bg !important;
  }
}
