.footer {
  background-color: $bg-footer;
  padding: 30px 0px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  width: 100%;
}

footer {
  display: flex;
  p {
    margin: 0;
    color: #ffffff;
  }
  a {
    color: #ffffff;
    &.none {
      display: none;
    }
  }
  .logo_info {
    display: flex;
    flex-grow: 2;
    flex-basis: 0;
  }
  .logo {
    background-image: url(/assets/common/smeg_logo.png);
    background-repeat: no-repeat;
    width: 100px;
    background-size: 100%;
    margin: 0px;
  }
  .info {
    margin-left: 50px;
  }
  .links {
    flex-grow: 1;
    flex-basis: 0;
  }
  .right {
    flex-grow: 2;
    flex-basis: 0;
    text-align: right;
    position: relative;
    margin-right: 10px;
    .socials a {
      display: inline-block;
      height: 50px;
      width: 50px;
      background-image: url("/assets/common/facebook_ico.svg");
      background-size: 42px;
      background-repeat: no-repeat;
      background-position: center center;
      &.facebook {
        background-image: url("/assets/common/facebook_ico.svg");
      }
      &.twitter {
        background-image: url("/assets/common/twitter_ico.svg");
      }
      &.linkedin {
        background-image: url("/assets/common/linkedin_ico.svg");
      }
      &.instagram {
        background-image: url("/assets/common/instagram_ico.svg");
      }
      &.facebook:hover {
        background-image: url("/assets/common/facebook_ico_o.svg");
      }
      &.twitter:hover {
        background-image: url("/assets/common/twitter_ico_o.svg");
      }
      &.linkedin:hover {
        background-image: url("/assets/common/linkedin_ico_o.svg");
      }
      &.instagram:hover {
        background-image: url("/assets/common/instagram_ico_o.svg");
      }
    }
    .language_footer {
      height: 40px;
      margin: 5px;
      a {
        height: 30px;
        display: inline-block;
        margin: 5px;
      }
      .flag_footer img {
          height: 30px;
          width: 40px;
        }
    }
    .legals {
      margin: 5px;
      bottom: 0px;
      right: 0px;
    }
  }
  .close {
    color: white;
    &:hover {
      color: white;
    }
  }
  #modal_close {
    color : #3065A9;
  }
  .modal-body {
    a {
      color: black;
    }
    p {
      color: black;
    }
  }
}

/* footer .right .socials a i.fab { font-size: 30px; margin: 15px 5px; } */

@media screen and (min-width: 990px) {
  .footer {
    padding-left: 240px;
  }
}

@media screen and (max-width: 767px) {
  .footer {
    display: none;
  }
}
@media screen and (max-width: 991px) and (min-width: 767px) {
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (min-width: 1200px) {
  footer {
    width : auto;
    // width: 1350px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
  }
}