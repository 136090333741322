
.body_account {
  .public_page .restore_information {
    text-align: left;
    margin: 20px;
    font-size: 14px;
  }

  .pds_input {
    margin-bottom: 30px;
  }

  .form-control.form-account {
    height: 34px;
    padding-left: 20px;
    margin-bottom: 10px;
  }


  .alert {
    z-index: 5;
    display: none;
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translate(-50%);

  }

  .grecaptcha-badge{
    z-index: 5;
  }

  @media screen and (max-width: 767px) {
    .public_page {
      .public_left_bloc.account_bloc {
        padding: 20px 20px 20px;
      }
      .introduction_bloc {
        display: none;
      }
    }

    .alert {
      width: calc(100% - 50px);
      display: none;
      z-index: 5;
      position: fixed;
      top: 40px;
      left: 25px;
    }

  }
}
