/*Specific font for admin*/
/* .admin {
    background: #04A9D0;
} */
@import "./admin/importer.scss";
@import "./admin/dashboard.scss";
@import "./admin/news_feed.scss";
@import "./admin/admin_settings.scss";

.main-content .alert {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  z-index: 5;
}

.draggable_item {
  cursor: grab;
}

tr:not(.sortable-chosen):not(.sortable-ghost).no_hover:hover {
  background-color: white;
}

.sortable-chosen {
  &:not(.sortable-ghost) {
    opacity: 1 !important;
    background-color: #C8EBFB !important;
  }
  &.sortable-ghost {
    opacity: 0 !important;
  }
}

.sortable-fallback {
  opacity: 0 !important;
}

.sortable-ghost {
  opacity: 1;
  background-color: #C8EBFB !important;
}
