#main-menu {
  background-color: #fff;
  border: none;
  li a {
    display: block;
    color: $text-secondary;
  }
  #left-navbar.nav {
    margin-left: 40px;
    li {
      > a {
        font-size: 18px!important;
        font-weight: 600;
        border-bottom: 5px solid transparent;
        padding: 18px 25px;
      }
      &.active a, a:hover {
        border-bottom: 5px solid $text-primary;
        background-color: transparent;
      }
    }
    .open > a {
      border-bottom: 5px solid $text-primary;
      background-color: transparent;
    }
  }
  .dropdown-toggle {
    padding: 8px;
    margin-right: 8px;
  }
  .dropdown-menu {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    ul, li, a {
      margin: 0;
      padding: 0;
      text-decoration: none;
      list-style: none;
    }
  }
  .visible-xs {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
    ul, li, a {
      margin: 0;
      padding: 0;
      text-decoration: none;
      list-style: none;
    }
  }
  .navbar-collapse {
    margin: 0;
    padding: 0;
    text-decoration: none;
    list-style: none;
  }
  .dropdown-menu, .visible-xs {
    min-width: 200px;
  }
  .dropdown-menu a, .visible-xs a {
    padding: 8px;
    padding-right: 12px;
    padding-left: 12px;
  }
  .dropdown-menu li.active a, .visible-xs li.active a, .dropdown-menu li a:hover, .visible-xs li a:hover {
    font-weight: normal;
  }
  .dropdown-menu ul.sub-menu, .visible-xs ul.sub-menu {
    background-color: #FAFDFE;
    box-shadow: inset 0 10px 10px -12px #90c5f6;
    position: relative;
    width: 100%;
  }
  .dropdown-menu ul.sub-menu li a, .visible-xs ul.sub-menu li a {
    padding-left: 20%;
  }
  .dropdown-menu .title, .visible-xs .title {
    font-size: 1.1em;
    font-weight: 700;
    padding: 20px;
    text-align: center;
    color: #777777;
  }
  .dropdown-menu .title i, .visible-xs .title i {
    font-size: 1.5em;
    vertical-align: bottom;
  }
  .dropdown-menu .divider, .visible-xs .divider {
    width: 100%;
    height: 1px;
    background-color: #e5e5e5;
  }
  button.navbar-toggle {
    float: left;
    border: none;
    &:hover, &:focus {
      background-color: transparent;
    }
    .icon-bar {
      background-color: #777777;
    }
  }
  #menu_contract {
    height: 45px;
    display: flex;
    align-items: center;
  }
  .contract_content {
    display: flex;
    align-items: center;
    .contract_icon {
      font-size: 2.5em;
    }
    .contract_info {
      padding-right: 10px;
      padding-left: 10px;
      font-size: 12px;
      line-height: 12px;
      width: 90%;
      .address {
        max-width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
      }
      .name {
        padding-right: 10px;
      }
      .status {
        position: relative;
        top: -2px;
      }
      .text-right .status {
        top: 0px;
      }
    }
  }
  .contract_list, .contract_search {
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .contract_list .status, .contract_search .status {
    position: relative;
    top: -5px;
  }
  .contract_list li.selected, .contract_search li.selected {
    border-right: green solid 5px;
  }
  .contract_list .label, .contract_search .label {
    font-size: 8px;
    padding-top: 3px;
    vertical-align: bottom;
  }
}

@media screen and (max-width: 767px) {
  #main-menu li a {
    .glyphicon, .fa, .flag-icon {
      margin-left: 15px;
      margin-right: 10px;
      max-width: 1em;
    }
  }
}

@media screen and (max-width: 1199px) {
  #main-menu #left-navbar.nav {
    margin-left: 20px;
  }
}

@media screen and (max-width: 991px) {
  #main-menu #left-navbar.nav {
    margin-left: 0px;
  }
}

@media screen and (max-width: 1199px) {
  #main-menu #left-navbar.nav li > a {
    padding: 18px 16px;
  }
}

@media screen and (max-width: 991px) {
  #main-menu #left-navbar.nav li > a {
    font-size: 14px;
    padding: 18px 8px;
  }
}

@media screen and (max-width: 767px) {
  #main-menu {
    .dropdown-menu li:first-child, .visible-xs li:first-child, .dropdown-menu li:last-child, .visible-xs li:last-child {
      padding-top: 20px;
    }
    .dropdown-menu li a:last-child, .visible-xs li a:last-child {
      padding-bottom: 20px;
    }
    .dropdown-menu a, .visible-xs a {
      color: #777;
      padding-top: 8px;
      padding-right: 12px;
      padding-left: 12px;
      padding-bottom: 10px;
      font-size: 16px;
    }
  }
}

#right-navbar {
  .lang {
    top: 8px;
    .dropdown-toggle {
      font-size: 1.2em;
    }
    .sub-menu {
      z-index: 10;
      list-style: none;
      display: block;
    }
  }
  .languages {
    text-align: center;
    a {
      display: inline-block;
      vertical-align: top;
    }
    img {
      width: 20px;
    }
  }
}

@media screen and (max-width: 1199px) and (min-width: 992px) {
  #main-menu.navbar {
    #left-navbar a {
      padding-right: 12px;
      padding-left: 12px;
    }
    .contract_content .contract_info .address {
      max-width: 140px;
    }
  }
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {
  #main-menu {
    &.navbar .navbar-header {
      position: fixed;
      z-index: 10;
      top: 0;
      left: 0;
      width: 100vw;
      margin: 0;
      background-color: #FAFDFE;
      box-shadow: 0 0 7px 3px grey;
    }
    .navbar-collapse {
      font-size: 14px;
      position: fixed;
      z-index: 9;
      top: 0;
      left: 0;
      width: 100%;
      max-height: 100%;
      padding-top: 50px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #fff;
      box-shadow: 0 0 6px 12px rgba(0, 0, 0, 0.175);
      /*overflow: scroll;*/
    }
    .navbar-brand {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
  }
  /* body > nav + .container {
    margin-top: -10px;
  } */
}

/*
This file is loaded after all others CSS and can overload default display
*/
/*--Menu principal*/
/**********************************************/

#logo_brand_header_public {
  background-image: url("/specififc/logo/logo.png");
  background-size: auto 60px;
  height: 60px;
  width: 205px;
  margin-left: auto;
  margin-right: auto;
  background-repeat: no-repeat;
}

#logo_brand_header_menu {
  background-image: url("/specific/logo/logo.png");
  background-size: 87px;
  height: 25px;
  width: 87px;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.btn .label {
  position: relative;
  top: -1px;
}