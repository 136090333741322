.body_metigate {
  color: #666666;

  .metigate_view {
    position: relative;
    &:before{
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      @media (min-width: 768px) {
        top: 5%;
        left: -5%;
      }
      background: url("/assets/meteo_icons/background_metigate.svg") no-repeat;
      background-position: center;
      opacity: .08;
      width: 100%;
      height: 100%;
    }
  }

  #metigate_title {
    @media (max-width: 768px) {
      margin-top: 35px;
    }
    z-index: 10;
    font-size: 44px;
    color: $text-secondary;
  }

  .period-select {
    margin-top: 30px;
    @media (min-width: 768px) {
      margin-top: 35px;      
    }
    z-index: 10;
  }

  #data_row {
    z-index: 10;
    margin-top: 30px;
  }
  
  @media (min-width: 768px) and (max-width: 1199px){
    .dots-grid-days-text {
      padding-left: 20px;
    }
  }

  @media (min-width: 577px) {
    .graphics-container {
      width: 100%;
    }
  }

  .period-select {
    #month-select, #year-select{
      border-width: 0;
      border-radius: 30px;
      color: #666666;
      background-color: #E1E1E1;
      padding-left: 10px;
      width: 100%;
      height: 28px;
      @media (min-width: 768px) {
        min-width: 100px;
      }
      @media (max-width: 768px) {
        max-width: 150px;
      }
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("/images/common/nav_down.svg") 92% / 15% #E1E1E1 no-repeat !important;
      background-position: center;
    }
  }

  #graph_row {
    background-image: $gradient-bright-bg;
    color: white;
    @media screen and (min-width: 768px) {
      margin-bottom: 100px;
    }
  }

  #col_graph {
    z-index: 10;
  }

  #advice_value {
    @media (max-width: 768px) {
      font-size: 44px;
      color: $text-secondary;
    }
    @media (min-width: 768px) {
      font-size: 20px;
      font-weight: bold;
    }
  }
  
  #mr-meteo {
    position: absolute;
    bottom: 0;
    left: 8%;
    height: 450px; 
    width: 280px;
    z-index: 20;
    @media (max-width: 990px) {
      height: 400px;
      width: auto;
      left: 5%;
    }
  }
}
