/*
 * File: _variables.scss
 * File Created: Wednesday, 12th June 2019 5:35:19 pm
 * Author: Julien Blacas (julien.blacas@gridpocket.com)
 * -----
 * Last Modified: Thursday, 1st August 2019 4:37:35 pm
 * Modified By: Pierre Bonny (pierre.bonny@gridpocket.com>)
 * -----
 * GridPocket SAS Copyright (C) 2019 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */

.body_payment {
  .main {
    .payment_title {
      margin: 0;
      text-align: center;
      font-size: 44px;
      color: $text-secondary;
    }
    @media (max-width: 768px) {
      .payment_title {
        margin-top: 35px;
      }
    }
    .tickcontainer {
      height: 100%;
      position: relative;
      margin-left: 3%;
      margin-right: 3%;
      text-align: center;

      .go-to-invoice {
        margin-top: 30px;
      }
      a {
        border-radius: 20px;
        padding: 10px 40px 10px 40px;
        text-decoration: none;
        color: white;
        background-image: $gradient-red-short1
      }

      .successMsg {
        font-size: 16px;
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        margin: auto;
        height: 0;
        text-align: center;    
      }
      .signbg {
        position: absolute;
        top: 15%;
        left: 0;
        right: 0;
        margin: auto; 
        content:"\00a0";
        &.error{
          background: $gradient-red-short1
        }
        &.success{
          background:#7AD16B;
        }
        border-radius: 60px;
        -moz-border-radius: 60px;
        -webkit-border-radius: 60px;
        height: 120px;
        width: 120px;
        .fas {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 50px;
          color: white;
        }
      }
    }
  }
}