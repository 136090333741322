
.body_content .left-menu {
    flex: 0 0 220px;
    background-color: $navbar-bg;
    text-align: center;
    ul {
      position: sticky;
      position: -webkit-sticky;
      top: 0px;
    }
    a {
      color: #ffffff;
      height: 90px;
      padding: 0px;
      background-size: 42px;
      background-repeat: no-repeat;
      background-position: center 20px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      &:hover {
        background-color: $bg-footer !important;
      }
      &.nav-dashboard {
        background-image: url("/assets/menu_icons/dashboard_ico.svg");
      }
      &.nav-invoice {
        background-image: url("/assets/menu_icons/facture_ico.svg");
      }
      &.nav-consumption {
        background-image: url("/assets/menu_icons/analyse_ico.svg");
      }
      &.nav-energyprofile {
        background-image: url("/assets/menu_icons/usage_ico.svg");
      }
      &.nav-advices {
        background-image: url("/assets/menu_icons/ecoguide_ico.svg");
      }
      &.nav-simu {
        background-image: url("/assets/menu_icons/simulateur_ico.svg");
      }
      &.nav-metigate {
        background-image: url("/assets/menu_icons/meteo_ico.svg");
      }
      &.contact_link {
        background-image: url("/assets/menu_icons/contact_ico.svg");
        background-size: 45px;
        &:focus {
          background-image: url("/assets/menu_icons/contact_ico.svg");
          background-color: $navbar-bg;
        }
      }
      &.nav-dashboard:hover {
        background-image: url("/assets/menu_icons/dashboard_ico_o.svg");
      }
      &.nav-invoice:hover {
        background-image: url("/assets/menu_icons/facture_ico_o.svg");
      }
      &.nav-consumption:hover {
        background-image: url("/assets/menu_icons/analyse_ico_o.svg");
      }
      &.nav-energyprofile:hover {
        background-image: url("/assets/menu_icons/usage_ico_o.svg");
      }
      &.nav-advices:hover {
        background-image: url("/assets/menu_icons/ecoguide_ico_o.svg");
      }
      &.nav-simu:hover {
        background-image: url("/assets/menu_icons/simulateur_ico_o.svg");
      }
      &.nav-metigate:hover {
        background-image: url("/assets/menu_icons/meteo_ico_o.svg");
      }
      &.contact_link:hover {
        background-size: 45px;
        background-image: url("/assets/menu_icons/contact_ico_o.svg");
      }
    }
}

#mobile_navbar_collapse {
  z-index: 999;
  position: fixed !important;
}

.nav_mobile {
  z-index: 999;
  background-color: #fff;
  border: none;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 45px;
  margin-bottom: 0;
  .navbar-header {
    height: 45px;
    position: relative;
  }
  .navbar-brand {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 80px;
    }
  }
  .navbar-toggle {
    float: none;
    margin: 0;
    border: none;
    border-radius: 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    .icon-bar {
      background-color: $text-secondary;
    }
    &:hover, &:focus {
      background-color: transparent;
    }
  }
}

#mobile_navbar_collapse {
  &.navbar-collapse {
    padding: 0;
    height: auto;
    max-height: calc(100vh - 45px);
    position: absolute;
    top: 45px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 3px 15px 0px;
  }
  .navbar-nav {
    margin: 0;
  }
  .select_contract {
    display: block;
    background-color: $text-secondary;
    color: #fff;
    padding: 20px 15px 15px;
    background-image: url(/specific/background/contract_menu_bg.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;
    &:hover, &:focus {
      text-decoration: none;
      outline: none;
    }
    p {
      &.profile_name {
        opacity: 1;
        font-weight: 700;
      }
      margin: 0;
      font-size: 14px;
      opacity: 0.6;
    }
    .caret {
      position: absolute;
      right: 15px;
      bottom: 15px;
      transform: scale(1.3);
    }
    .status {
      position: absolute;
      right: 15px;
      top: 15px;
      transform: scale(1.3);
    }
  }
  .nav {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
    > li > {
      a {
        color: #666;
        padding: 8px 15px;
        &[href="/invoice"] {
          padding: 8px 17px;
          .glyphicon {
            margin-right: 42px;
          }
        }
      }
      .labelled {
        display: inline-block;
        width: 100%;
        > .profile-label {
          display: table;
          height: 20px;
          line-height: 20px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      a {
        .glyphicon {
          margin-right: 40px;
        }
        &:hover {
          background-color: transparent;
          color: #333;
        }
      }
      span.label {
        position: absolute;
        right: 15px;
        display: inline-block;
        transform: translateY(50%);
        line-height: normal;
      }
    }
  }
  .languages {
    display: table;
    margin: 0 auto;
    a {
      display: inline-block;
      padding: 15px 7px;
    }
    img {
      width: 20px;
    }
  }
  .disconnect_btn {
    width: 100%;
    color: #666;
    background-color: #ebebeb;
    .glyphicon {
      margin-left: 10px;
    }
  }
}

//!* Select Contract *!

//!* Navigation *!

@media screen and (max-width: 991px) {
  .body_content .left-menu {
    flex: 0 0 120px;
  }
}

@media screen and (max-width: 767px) {
  .left-menu {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  #mobile_navbar_collapse {
    display: none !important;
  }
}

@media screen and (max-width: 991px) {
  ul.nav-sidebar a.nav-invoice {
    height: 110px !important;
  }
}

//highlight navbar button
body.body_dashboard a.nav-dashboard,
body.body_invoice a.nav-invoice,
body.body_consumption a.nav-consumption,
body.body_energyprofile a.nav-energyprofile,
body.body_advices a.nav-advices,
body.body_simu a.nav-simu,
body.body_metigate a.nav-metigate {
  background-color: $bg-footer;
  &[href="/dashboard"] {
    background-image: url("/assets/menu_icons/dashboard_ico_o.svg");
  }
  &[href="/invoice"] {
    background-image: url("/assets/menu_icons/facture_ico_o.svg");
  }
  &[href="/consumption?isAmm=true"] {
    background-image: url("/assets/menu_icons/analyse_ico_o.svg");
  }
  &[href="/consumption?isAmm=false"] {
    background-image: url("/assets/menu_icons/analyse_ico_o.svg");
  }
  &[href="/energyprofile"] {
    background-image: url("/assets/menu_icons/usage_ico_o.svg");
  }
  &[href="/advices"] {
    background-image: url("/assets/menu_icons/ecoguide_ico_o.svg");
  }
  &[href="/simu"] {
    background-image: url("/assets/menu_icons/simulateur_ico_o.svg");
  }
  &[href="/weather"] {
    background-image: url("/assets/menu_icons/meteo_ico_o.svg");
  }
}

//preload navbar images
body::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1;
  content: url("/assets/menu_icons/meteo_ico_o.svg") url("/assets/menu_icons/simulateur_ico_o.svg") url("/assets/menu_icons/ecoguide_ico_o.svg") url("/assets/menu_icons/usage_ico_o.svg") url("/assets/menu_icons/analyse_ico_o.svg") url("/assets/menu_icons/facture_ico_o.svg") url("/assets/menu_icons/dashboard_ico_o.svg") url("/assets/menu_icons/contact_ico_o.svg");
}

