.body_init {
  .public_page.init {
    .public_left_bloc, .introduction_bloc {
      padding: 20px;
    }
    .public_left_bloc h1 + p {
      font-size: 14px;
      line-height: normal;
      text-transform: initial;
      margin-bottom: 5px;
      color: #666;
    }
  }
  
  @media screen and (min-width: 768px) {
    .public_page.init {
      .public_left_bloc {
        padding: 30px 40px 40px;
      }
      .introduction_bloc {
        padding: 30px 40px 40px;
        p {
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          max-width: calc(100% - 60px);
        }
      }
    }
  }
}

