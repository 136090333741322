.body_restore {
  
  .public_page .public_left_bloc .fa-spinner.hidden {
    display: none;
  }

  .restore_title {
    text-align: center;
  }
  
  @media screen and (max-width: 767px) {
    .public_page {
      .public_left_bloc.restore_bloc {
        padding: 20px 20px 20px;
      }
      .introduction_bloc {
        display: none;
      }
    }
  }

  .btn {
    &.btn-primary {
      &.go_back_ico {
        padding-right: 5%;
        padding-left: 5%;
        margin-top: 95%;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        &.none {
          display: none;
        }
        &:after {
          content: '';
          display: block;
          width: 20px;
          height: 25px;
          position: absolute;
          top: 55%;
          background-size: 15px;
          transform: translateY(-50%);
          background-repeat: no-repeat;
          background-image: url(/images/common/nav_left.png);
              
        }
      }  
    }
  }  
}