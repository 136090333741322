.add_button {
  @media screen and (max-width: 767px){
    border: 1px solid #666666;
  }
  @media screen and (max-width: 480px){
    margin-left: 15px;
    position: relative !important;
    margin-bottom: 10px;
    margin-top: -20px;
  }
}