/*
* File: _404.scss
* File Created: Monday, 12th August 2019 4:33:55 pm
* Author: Victor Houzé (victor.houze@gridpocket.com)
* -----
* Last Modified: Tuesday, 13th August 2019 11:04:04 am
* Modified By: Victor Houzé (victor.houze@gridpocket.com>)
* -----
* GridPocket SAS Copyright (C) 2019 All Rights Reserved
* This source is property of GridPocket SAS.
* Please email contact@gridpocket.com for more information.
*/

.body_404 {
  background: none;

  .navbar {
    height: 50px;
  }

  @media screen and (min-width: 768px) {
    img {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    img {
      width: 80%;
    }
  }

  .title_error {
    font-size: 44px;
  }

  .message_error {
    font-size: 30px;
    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }

  .button_404 {
    margin-top: 20px;
    #backtohome {
      @media screen and (max-width: 576px) {
        width: 65%;
        padding: 0;
      }
      a {
        color: white;
        font-size: 18px;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    background-color: transparent;
    padding-left: 0;

    .gridpocket {
      flex-grow: 2;
      flex-basis: 0;
      text-align: right;

      p {
        color: black;
      }

      a {
        font-weight: bold;
        color: black;
      }
    }
  }
}
