/*
* File: _maintenance.scss
* File Created: Wednesday, 14th August 2019 9:09:02 am
* Author: Victor Houzé (victor.houze@gridpocket.com)
* -----
* Last Modified: Wednesday, 14th August 2019 10:52:35 am
* Modified By: Victor Houzé (victor.houze@gridpocket.com>)
* -----
* GridPocket SAS Copyright (C) 2019 All Rights Reserved
* This source is property of GridPocket SAS.
* Please email contact@gridpocket.com for more information.
*/

.body_maintenance {
  background: none;

  .navbar {
    height: 50px;
  }

  .div_maintenance {
    margin-top: 30px;
  }

  .img_maintenance {
    margin-top: 30px;
    }

  @media screen and (max-width: 767px) {
    img {
      width: 70%;
    }
  }

  .title_error {
    display: inline;
    font-size: 44px;
  }

  .message_error {
    display: block;
    font-size: 30px;
    @media screen and (max-width: 576px) {
      font-size: 20px;
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    background-color: transparent;
    padding-left: 0;

    .gridpocket {
      flex-grow: 2;
      flex-basis: 0;
      text-align: right;

      p {
        color: black;
      }
      a {
        font-weight: bold;
        color: black;
      }
    }
  }
}
