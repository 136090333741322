.dashboard_admin_main_container {
  flex: 1;
  width: 100%;
}
.body_dashboard_admin {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .dataTables_wrapper {
    position: relative;
    .dataTables_processing {
      background-color: transparent;
      border: none;
      top: 60%;
      .loader-3-dots {
        transform: none;
        position: unset;
      }
    }
  }
  #search {
    height: 30px;
    border-radius: 10px;
    border-width: 1px;
    border: 1px solid #cccccc;
  }
}

.admin {
  .primary_bkgd {
    background-image: linear-gradient(to right, #7D51D9, #376EB3) !important;
    background-size: 100vw;
    .row {
      background-color: #337ab7;
    }
    color: white;
    border-radius: 0px;
    @media screen and (max-width: 767px){
      border: 1px solid #666666;
    }
  }
  .caret {
    color: white;
  }
}

table {
  &.contract_table.table-striped > tbody > tr {
    &:nth-of-type(2n+1) {
      background-color: rgba(250, 250, 250, 0.1) !important;
    }
    &:hover {
      background-color: rgba(250, 250, 250, 0.3) !important;
      cursor: pointer;
    }
  }
  &.user_table > tbody > tr.odd {
    background-color: rgba(200, 200, 200, 0.3);
    + tr.details {
      background-color: rgba(200, 200, 200, 0.3);
    }
  }
}

tr.details {
  > td {
    padding: 20px !important;
    padding-right: 40px !important;
    padding-left: 40px !important;
    color: white;
  }
  tr {
    background-color: transparent !important;
  }
}

.user_table {
  background-color: white;
}

.contract_table {
  background-color: rgba(2, 120, 223, 1) !important;
  width: 100%;
  mark {
    background: rgba(255, 255, 255, 1);
    color: rgba(2, 120, 223, 1);
  }
}

mark {
  background: rgba(2, 120, 223, 1);
  color: rgba(255, 255, 255, 1);
}

th {
  text-align: center !important;
}

.table-responsive {
  clear: both;
}

.options {
  display: flex;
  flex-direction: row;
  align-items: end;
  position: relative;
  justify-content: space-between;
  width: 700px;
  top: 45px;
  z-index: 2;
  margin-top: -20px;
  float: right;
  @media screen and (max-width: 1080px) {
    float: left;
    margin-bottom: 20px;
    top:  15px;
  }
  @media screen and (max-width: 767px){
    flex-flow: wrap;
    width: unset;
  }
  > div {
    display: flex;
    margin: 3px;
    span {
      margin-right: 10px;
    }
  }
  div.active span {
    color: #337ab7;
  }
  .checkbox_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 3px;
    transform: translateY(-25%);
    span {
      margin-right: 7px;
      font-size: 12px;
      color: #888;
    }
  }
}

/*********** CSS FROM W3C ***************************************/

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 21px;
  input {
    display: none;
  }
}

/* Hide default HTML checkbox */

/* The slider */

.body_dashboard_admin .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #AAA;
  -webkit-transition: .4s;
  transition: .4s;
  &:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 2px;
    bottom: 2px;
    background-color: #666;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

input {
  &:checked + .slider {
    background-color: #337ab7;
  }
  &:focus + .slider {
    box-shadow: 0 0 1px #337ab7;
  }
  &:checked + .slider:before {
    background-color: white;
    -webkit-transform: translateX(27px);
    -ms-transform: translateX(27px);
    transform: translateX(27px);
  }
}

/* Rounded sliders */

	/* .slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	} */

.fa-spinner {
  transform-origin: 50% 50%;
  -webkit-animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.billing_types_div {
  margin-top: 10px!important;
}