.body_public {
  padding-top: 0 !important;
  background-color: #3f6ab6;
  color: #666;
  font-family: 'Open Sans', sans-serif;
  background-image: url(/specific/background/main_bg.jpg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  background-repeat: no-repeat;

  footer div a .logo_smeg {
    width: 100px;
    cursor: pointer;
  }

  .btn{
    &.btn-primary {
      height: 50px;
      border-radius: 25px;
      font-family: $first-font;
      font-weight: normal;
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      line-height: 19px;
    }
  }

  /*====================
          GLOBAL
  ===================*/

  .exclamation-circle-icon {
    margin-right: 5px;
    width: 18px;
  }

  .rounded-check-ico {
    margin-right: 5px;
    width: 18px;
  }

  .error-p {
    color: $text-primary;
    display: none;
    font-family: $first-font;
    font-size: 12px;
    text-align: left;
    line-height: 14px;
    font-weight: normal;
  }

  .success_msg {
    color: #4BB543;
    display: none;
    font-family: $first-font;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    font-weight: normal;
    margin-bottom: 20px;
  }

  .content_bloc {
    box-shadow: none;
    padding: 0px;
    margin-bottom: 0;
  }

  /*====================================
          checkboxes style override
  ====================================*/

  input {
    &[type=checkbox] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      height: 16px;
      width: 16px;
      background: #fff;
      border: 1px solid rgba(136, 136, 136, 1);
      border-radius: 3px;
      margin-left: -30px !important;
      margin-top: -1px !important;
      cursor: pointer;
    }
    &[type="checkbox"]:checked::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f00c';
      display: block;
      color: rgba(136, 136, 136, 1);
      font-size: 13px;
      position: absolute;
      font-weight: 900;
    }
  }

  /*====================
          BLOC
  ===================*/

  .public_page {
    padding-top: 100px;
    .content_bloc {
      background-color: #fff;
      width: 1030px;
      max-width: 100%;
      margin: 0 auto;
      font-size: 0;
      position: relative;
      margin-bottom: 50px;
      padding: 0px;
      .shadow_box {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        -webkit-box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.35);
        -moz-box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.35);
        box-shadow: 10px 10px 18px 0px rgba(0, 0, 0, 0.35);
      }
      .decoration {
        background-color: #fff;
        opacity: .5;
        height: 100%;
        width: calc(100% - 60px);
        position: absolute;
        top: 0;
        left: 30px;
        transform: rotate(178deg);
        z-index: 1;
      }
    }
    .public_left_bloc {
      display: inline-block;
      background-color: #fff;
      vertical-align: top;
      width: 55%;
      height: 580px;
      padding: 74px 80px 80px;
      font-size: 14px;
      position: relative;
      z-index: 3;
      h1 {
        font-size: 26px;
        margin-top: 0;
        margin-bottom: 30px;
        font-weight: normal;
        line-height: 30px;
        font-family: $first-font;
      }
      button {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 15px;
      }
      .lost_password, .go-back-to-signin {
        text-align: right;
      }
      .lost_password a, .go-back-to-signin a {
        font-family: $first-font;
        font-size: 12px;
        color: rgba(136, 136, 136, 1);
        text-align: right;
        line-height: 14px;
        font-weight: normal;
        margin-right: 5px;
      }
      .create_account_link {
        display: inline-block;
        text-align: center;
        display: block;
        font-family: 'Open Sans';
        font-size: 16px;
        color: rgba(55, 110, 179, 1);
        text-align: center;
        line-height: 22px;
        font-weight: normal;
        cursor: pointer;
      }
    }
    .introduction_bloc {
      display: inline-block;
      vertical-align: top;
      width: 45%;
      height: 630px;
      padding: 80px 0 0;
      font-size: 18px;
      text-align: center;
      background-image: url(/specific/background/introduction_bg.jpg);
      background-size: cover;
      background-position: right bottom;
      position: relative;
      z-index: 2;
      .logo_mynexio {
        width: 285px;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        margin-bottom: 45px;
      }
      h2 {
        color: $text-primary;
        font-size: 22px;
        margin-bottom: 70px;
        margin-left: 15px;
        margin-right: 15px;
        min-height: 75px;
      }
    }
    footer {
      width: 1030px;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 30px;
      font-size: 0;
      .smeg {
        display: inline-block;
        vertical-align: bottom;
        width: 50%;
        a:hover {
          opacity: .7;
          transition: .3s;
        }
      }
      .gridpocket {
        display: inline-block;
        vertical-align: bottom;
        width: 50%;
        font-size: 14px;
        text-align: right;
        p {
          margin-bottom: 0;
          color: #fff;
          a {
            color: #fff;
            font-weight: bold;
            &:hover {
              text-decoration: none;
              opacity: .7;
              transition: 0.3s;
            }
          }
        }
      }
    }
  }

  /* Public left Bloc */

  /* Introduction_bloc */

  @media screen and (max-width: 1199px) {
    .public_page {
      padding-top: 100px;
      .content_bloc {
        margin-bottom: 50px;
      }
      .public_left_bloc {
        // height: 550px;
        height: 630px;
      }
      .introduction_bloc {
        height: 630px;
        .logo_mynexio {
          width: 200px;
        }
      }
    }
  }

  @media screen and (max-width: 991px) {
    .btn.btn-lg {
      font-size: 16px;
      font-weight: 600;
    }
    .public_page {
      .public_left_bloc {
        height: 500px;
        padding: 30px 30px 0;
      }
      .introduction_bloc {
        padding: 50px 30px 0;
        h2 {
          margin-bottom: 100px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    .public_page {
      padding-top: 30px;
      .public_left_bloc {
        width: 100%;
        height: auto;
        padding: 50px;
      }
      .introduction_bloc {
        width: 100%;
        height: auto;
        padding: 50px;
        .logo_mynexio, h2 {
          margin-bottom: 30px;
        }
      }
    }
  }

  /* Introduction slider */

  .quote_bloc {
    .item {
      position: relative;
      font-size: 15px;
      &:before {
        content: '';
        display: block;
        width: 67px;
        height: 67px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .slider {
      margin-bottom: 20px;
      .slick-arrow {
        visibility: hidden;
      }
      .slick-dots li {
        margin: 0 2px;
        button::before {
          opacity: 1;
          color: #ccc;
          font-size: 13px;
        }
        &.slick-active button::before {
          color: $text-primary;
        }
      }
    }
  }

  /*====================
          LINKS
  ===================*/

  .link {
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
      text-decoration: none;
    }
  }

  .link_primary {
    color: $text-primary;
    font-size: 18px;
    font-weight: 600;
    &:hover {
      color: #e55753;
    }
  }

  .link_secondary {
    color: $text-secondary;
    font-size: 18px;
    font-weight: 600;
    &:hover {
      color: #0090b2;
    }
  }

  .link_primary .info_ico {
    display: inline-block;
    margin-left: 12px;
    background-color: white;
    width: 33px;
    height: 33px;
    vertical-align: middle;
    border-radius: 50%;
    position: relative;
    &:before {
      content: '';
      top: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: url(/images/common/info_ico.svg);
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .link_secondary .user_ico:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(/images/common/user_white_ico.svg);
    background-size: 15px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  /*== FORMS ==*/

  .form-control {
    height: 50px;
    font-size: 16px;
    font-family: $first-font;
    color: rgba(112, 112, 112, 1);
    border: none;
    background-color: $bg-primary;
    border-radius: 25px;
    padding-left: 60px;
    box-shadow: none;
    &:hover, &:focus {
      box-shadow: none;
    }
  }

  .has-error.form-control {
    border: 1px solid #a94442;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  }

  /* Icons */

  .field-icon {
    float: right;
    margin-right: 21px;
    margin-top: -36px;
    position: relative;
    z-index: 2;
    width: 30px;
    height: 25px;
    cursor: pointer;
  }

  .user-input {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 22px;
      top: 0;
      bottom: 0;
      width: 20px;
      background: url(/images/common/user_grey_ico.svg) (center / contain) no-repeat;
    }
  }

  .password-input {
    position: relative;
    background-color: $bg-primary;
    border-radius: 25px;
    &:before {
      content: "";
      position: absolute;
      left: 22px;
      top: 0;
      bottom: 0;
      width: 20px;
      background: url(/images/common/locked_grey_ico.svg) (center / contain) no-repeat;
    }

    input.locked_ico {
      width: 85%;
    }
  }
}

