
$secondary_color: #376EB3;

.body_survey {
  h3 span {
    color: grey;
    font-size: 15px;
  }
  
  .title {
    text-align: center;
  }
  
  .btn {
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
  }

  .cachet_etat {
    width: 400px;
  }
  
  @media screen and (max-width: 991px) {
    .cachet_etat {
      width: 320px;
    }
  }
  
  .line_timeline, .icon_timeline {
    position: relative;
    display: inline-block;
  }
  
  .line_timeline {
    width: 100px;
    margin-left: 2px;
    border-bottom: 2px solid #b5c8d4;
    margin-right: 2px;
    margin-left: 4px;
  }
  
  .icon_timeline {
    top: 3px;
    margin: -4px;
  }
  
  .nav_part {
    display: inline;
    height: 30px;
    .part_label {
      font-size: 15px;
      height: 55px;
      cursor: pointer;
      position: absolute;
      left: 50%;
      bottom: -25%;
      transform: translate(-50%);
      width: 124px;
      color: #b5c8d4;
      z-index: 8;
    }
  }
  
  i.fa-exclamation {
    color: #b5c8d4;
    display: none;
  }
  
  .nav_part {
    &.previous_active .line_timeline, &.active .line_timeline, &.previous_active .icon {
      border-color: #af3a4b;
    }
    &.active {
      .icon {
        border-color: #af3a4b;
      }
      .part_label {
        color: #c84257;
      }
    }
    &.previous_active {
      .part_label {
        color: #c84257;
      }
      i {
        &.fa-check, &.fa-exclamation {
          color: #c84257;
        }
      }
    }
    &.active i.fa-exclamation, &.previous_active i.fa-check {
      color: white;
    }
  }
  
  .survey {
    .icon {
      width: 24px;
      height: 24px;
      line-height: 20px;
      border: 2px solid  #b5c8d4;
      border-radius: 50%;
      display: block;
      position: relative;
      top: 4px;
    }
    .title .info_button {
      text-align: right;
      padding-top: 20px;
      font-size: 120%;
      color: #8f9a7e;
      cursor: pointer;
      position: relative;
      top: 5px;
      i {
        font-size: 160%;
        vertical-align: bottom;
      }
    }
  }
  
  .complete .icon {
    background: #b5c8d4;
  }
  
  .survey .active .icon, .previous_active.complete .icon {
    background: #c84257;
  }
  
  /* Complete part */
  
  .nav_part {
    i.fa-check {
      display: none;
      color: white;
    }
    &.complete i.fa-check {
      display: inline;
    }
    &.warning {
      i.fa-exclamation {
        display: inline;
      }
      .icon {
        top: 1px;
        margin-top: -11px;
      }
    }
    &.complete .icon {
      top: 1px;
      margin-top: -11px;
    }
  }
  
  /* Complete part */
  
  /* Complete part */
  
  @media screen and (max-width: 1199px) and (min-width: 992px) {
    .btn-group.btn-group-justified .btn {
      padding: 5px;
    }
  }
  
  @media screen and (max-width: 991px) {
    .line_timeline {
      width: 80px;
    }
    .part .col-md-4 {
      border: none !important;
    }
  }
  
  .mobile_nav {
    display: none;
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 544px) {
    .nav_container.pc {
      display: none;
    }
    .mobile_nav {
      display: block;
      margin-top: 15px;
      .part_label {
        display: none;
      }
      .line_timeline {
        width: 11%;
        border-width: 2px;
        margin-left: 4px;
        top: 0px;
      }
      .fa-stack {
        font-size: 18px;
        cursor: pointer;
      }
      .active {
        .part_label {
          display: block;
          top: 40px;
          font-size: 19px;
        }
        &[data-navcustom="part1"] .part_label {
          transform: translate(-15%);
          text-align: left;
        }
        &[data-navcustom="part5"] .part_label {
          transform: translate(-87%);
          text-align: right;
        }
      }
    }
  }
  
  .pd30 {
    padding: 30px;
  }
  
  .pd40 {
    padding: 40px;
  }
  
  .pd20 {
    padding: 20px;
  }
  
  .next_container {
    text-align: center;
    margin-bottom: 20px;
    button {
      display: inline-block;
      position: static;
      cursor: pointer;
    }
    .btn_next, .btn_previous {
      color: white;
      background-image: $gradient-red-short1;
      &:hover {
        background-image: linear-gradient(to right, rgb(187, 71, 68), rgb(181, 49, 77));
      }
    }
    .btn_finish {
      border-radius: 30px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  
  /*
  .form-control.active{
      margin-top: 3px;
  }
  */
  
  .part {
    label.other p {
      position: relative;
      top: 50%;
    }
    .active p {
      color: black !important;
    }
    position: fixed;
    z-index: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
  }
  
  .display_part {
    position: relative;
    display: block;
    visibility: visible;
    height: initial;
    transition: all ease 1s;
    opacity: 1;
  }
  
  .part .col-md-4 {
    border: solid #337ab7 1px;
    border-top: none;
    border-bottom: none;
    &:first-child {
      border-left: none;
      position: relative;
      right: -1px;
    }
    &:last-child {
      border-right: none;
      position: relative;
      right: 1px;
    }
  }
  
  .question {
      /*box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.3);
    border-radius:4px;*/
    margin-bottom: 30px;
    /*background: rgba(255,255,255,0.8);*/
  }
  
  .part .question h3 {
    font-size: 120%;
    font-weight: 700;
    text-align: justify;
    margin-top: 0;
    margin-bottom: 20px;
  }
  
  .radio_group {
    display: none;
  }
  
  /**SELECT SURCHAGE **/
  
  .btn-group {
    button.dropdown-toggle {
      min-width: 100%;
      text-align: right;
      overflow: hidden;
    }
    ul.dropdown-menu {
      min-width: 100%;
      li {
        text-align: center;
      }
    }
    button.dropdown-toggle span.selected {
      float: left;
      width: 95%;
      overflow: hidden;
      text-align: center;
    }
  }

  .btn-group {
    &:hover{
      .btn:hover{
        background-image: $gradient-red-short1
      }
      .btn:not(:hover) {
        background-image: unset;
      }
    }
    .btn.active {
      background: unset;
      background-image: $gradient-red-short1;
    }
  }
  
  @media screen and (max-width: 544px) {
    .answers {
      text-align: center;
    }
  }
  
  .answers + .input {
    margin-top: 3px;
  }
  
  /********* BTN WITH SVG ****************/
  
  .part .question .pickup_answer.btn {
    width: 100px;
    height: 100px;
    margin-top: 3px;
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 2px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    position: relative;
    /*overflow: hidden;*/
  }
  
  .pickup_answer.btn-default {
    border-radius: 13px;
    background-color: #ebebeb;
    &.active {
      border-color: #c84257 !important;
      border: solid 2px;
      color: black !important;
      background-color: white !important;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.4);
      &.focus, &:focus, &:hover {
        border-color: #c84257 !important;
        border: solid 2px;
        color: black !important;
        background-color: white !important;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.4);
      }
    }
    &.focus:active {
      border-color: #c84257 !important;
      border: solid 2px;
      color: black !important;
      background-color: white !important;
      box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.4);
    }
    &:active {
      &:focus, &:hover {
        border-color: #c84257 !important;
        border: solid 2px;
        color: black !important;
        background-color: white !important;
        box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.4);
      }
    }
  }
  
  /*.part .question .btn-default:hover, {
    border-color: #1e8c99;
    color: #1e8c99 ; 
    background: white ;
  }*/
  
  // .btn-default.active, .bootstrap-select .btn-default.active {
  //   border-color: #c84257 !important;
  //   color: white !important;
  //   // background-color: #c84257 !important;
  // }
  
  // .btn-default {
  //   &.active:focus, &.focus:active {
  //     border-color: #c84257 !important;
  //     color: white !important;
  //     // background-color: #c84257 !important;
  //   }
  //   &:active {
  //     &:focus, &:hover {
  //       border-color: #c84257 !important;
  //       color: white !important;
  //       background-color: #c84257 !important;
  //     }
  //   }
  // }
  
  .part .question {
    .btn {
      .img_container {
        height: 85px;
        .default {
          width: 90%;
          max-height: 90%;
          position: relative;
          top: 50%;
          transform: translate(0, -50%);
          border-top-right-radius: 13px;
          border-top-left-radius: 13px;
        }
        > * {
          z-index: 0;
          vertical-align: middle;
          width: 100%;
          filter: gray;
          filter: grayscale(100%) contrast(70%) brightness(125%);
          -webkit-filter: grayscale(100%) contrast(70%) brightness(125%);
          -ms-filter: grayscale(100%) contrast(70%) brightness(125%);
          text-align: center;
          display: inline-block;
        }
      }
      .area_click {
        cursor: pointer;
      }
    }
    label.pickup_answer:hover {
      background-color: #cccccc !important;
      color: #666;
    }
    .btn {
      &:hover .img_container > *, &.active .img_container > * {
        filter: none;
        -webkit-filter: none;
        -ms-filter: none;
      }
      .text_container {
        font-size: .8em;
        position: relative;
        white-space: normal;
        margin-top: -7px;
      }
    }
    .pickup_answer.btn.active p {
      color: #1e8c99;
    }
  }
  
  .rangeslider__fill {
    background: #1e8c99 !important;
  }
  
  .rangeslider.rangeslider--horizontal {
    height: 15px;
  }
  
  .rangeslider__handle {
    top: -6px !important;
    width: 27px !important;
    height: 27px !important;
    box-shadow: none !important;
  }
  
  .part .question .rangeslider--horizontal {
    margin-top: 30px;
  }
  
  .input-group.success {
    input {
      border-color: #ebebeb;
    }
    span {
      border: #ebebeb;
      background-color: #c84257;
      color: white;
      -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
      box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
  }
  
  input[type="range"] {
    padding: 0;
    height: 20px;
    margin-top: 15px;
  }
  
  .pickup_answer.text-center.btn.btn-default.active::before {
    content: "\f00c";
    width: 20px;
    height: 20px;
    position: absolute;
    top: -10px;
    font-family: 'Font Awesome 5 Free';
    color: white;
    right: -10px;
    z-index: +1;
    display: block;
    border-radius: 50%;
    background: #c84257;
  }
  
  .survey {
    .part .question .pickup_answer.btn.active p {
      color: black;
    }
    .pickup_answer.btn-default {
      &.active {
        p, &.focus p, &:focus p, &:hover p {
          color: black;
        }
      }
      &.focus:active p {
        color: black;
      }
      &:active {
        &:focus p, &:hover p {
          color: black;
        }
      }
    }
  }
  hr {
    border-color: #eee;
  }
  .checkbox-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: flex-start;
    margin-top: 24px;
    position: relative;

    > label {
        font-family: $first-font;
        font-size: 14px;
        margin-left: 10px;
        text-align: left;
        width: 90%;
        font-weight: 300;
        
        margin-bottom: 0px; //Bootstrap fix

        @media #{$big-screen} {
            font-size: 16px;
        }

        &:hover{
            cursor: pointer;
        }
    }

    > input {
        -webkit-appearance: none;
        border: 2px solid $secondary-color;
        width: 18px;
        height: 18px;
        transition: .1s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0px; //Bootstrap fix

        &:hover {
            cursor: pointer;
        }

        &:active, &:checked {
            border: 5px solid $secondary-color;
        }

        &:checked {
            background-color: $secondary-color;
        }

        &:checked:after {
            content: "\f00c";
            font-family: "Font Awesome 5 Free";
            font-style: normal;
            font-weight: 900;
            text-decoration: inherit;
            font-size: 17px;
            color: white;
        }
    }
}
}