.modal_rib {
  .close {
    opacity: 1;
  }
  
  .success_msg {
    color: #4BB543;
    display: none;
    font-family: $first-font;
    font-size: 12px;
    text-align: left;
    line-height: 14px;
    font-weight: normal;
    margin-bottom: 20px;
    .rounded-check-ico {
      margin-right: 5px;
      width: 18px;
    }
  }
  .modal-header {
    padding: 0;
    .mobile_modal_navbar {
      display: flex;
      align-items: flex-end;
      position: relative;
      background-image: $gradient-bright-bg;
      box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
      height: 90px;
      @media screen and (min-width: 768px) {
        display: none;
      }
      .nav_arrow {
        height: 30px;
        width: 30px;
        position: absolute;
        left: 5px;
        bottom: 15px;
        cursor: pointer;
      }
      .nav_text {
        font-size: 18px;
        color: white;
        margin-left: 40px;
        margin-bottom: 20px;
      }
    }
  }

  .fa-spinner {
    margin-left: 5px;
  }
  
  .modal_title {
    text-align: center;
    font-size: 44px;
    color: $text-secondary;
  }
  .subtitle {
    text-align: center;
    font-size: 26px!important;
    @media screen and (max-width: 767px) {
      font-size: 16px !important;
    }
  }
  @media screen and (min-width: 768px) {
    opacity: 0;
    -webkit-transition: opacity .3s;
    -o-transition: opacity .3s;
    transition: opacity .3s;
    &.in {
      opacity: 1;
    }
  }
}

#modalRibSelection {
  .loader-3-dots {
    top: calc(100% + 50px);
    left: 50%;
  }
  padding: 0 !important;
  line-height: normal;
  > .modal-dialog {
    margin: 0;
    background-color: $bg-primary;
    width: 100%;
    min-height: 100vh;
    > .modal-content {
      min-height: 100vh;
      background-color: $bg-primary;
      @media screen and (max-width: 767px) {
        margin-top: 0;
        border-radius: 0;
      }
      > .modal-header {
        border-bottom: none;
      }
      .modal-body {
        button {
          border-radius: 32px;
          margin-left: 10px;
          @media screen and (max-width: 767px) {
            margin: 0 10px;
            width: calc(100% - 20px);
          }
        }
        .ribs_items {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          margin-left: auto;
          margin-right: auto;
          position: relative;
          padding-top: 50px;
          @media screen and (min-width: 1860px) {
            width: 1804px;
          }
          @media screen and (max-width: 1859px) {
            width: 1354px;
          }
          @media screen and (max-width: 1401px) {
            width: 904px;
          }
          @media screen and (max-width: 956px) {
            width: 452px;
          }
          .rib_creation {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            margin: 0;
            top: 0;
            z-index: 8000;
          }
          .rib_view {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 431px;
            margin: 10px 10px;
            font-size: 14px;
            line-height: 20px;
            padding: 20px 35px;
            cursor: pointer;
            &:hover {
              background-color: $text-secondary!important;
              color: white!important;
              .strong_div {
                color: white!important;
              }
            }
            &.active {
              background-color: $text-secondary;
              color: white;
              .strong_div {
                color: white;
              }
            }
            &:not(.active) {
              background-color: white;
              color: black;
              .strong_div {
                color: $text-secondary
              }
            }
            .row {
              &.heading {
                margin-bottom: 15px;
              }
            }
          }
        }
        .ribs_items_mobile {
          display: flex;
          flex-direction: column;
          
          .rib_view_mobile {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 10px;
            font-size: 14px;
            line-height: 20px;
            padding: 20px 35px;
            cursor: pointer;
            border-radius: 5px;
            &:hover {
              background-color: $text-secondary!important;
              color: white!important;
              .strong_div {
                color: white!important;
              }
            }
            &.active {
              background-color: $text-secondary;
              color: white;
              .strong_div {
                color: white;
              }
            }
            &:not(.active) {
              background-color: white;
              color: black;
              .strong_div {
                color: $text-secondary
              }
            }
            .row {
              &.heading {
                margin-bottom: 15px;
              }
            }
          }
        }
      }
    }
  }
}
#modalRibCreation {
  padding: 0 !important;
  line-height: normal;
  > .modal-dialog {
    width: 100%;
    margin: 0;
    height: 100%;
    background-color: white;
    overflow: hidden;
    > .modal-content {
      height: 100%;
      background-color: white;
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
      > .modal-header {
        border-bottom: none;
        .subtitle {
          @media screen and (max-width: 767px) {
            display: none;
          }
        }
        .go_back_arrow {
          width: 60px;
          position: absolute;
          cursor: pointer;
          height: 60px;
          border-radius: 50%;
          background-image: url(/assets/common/arrow_blue.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      > .modal-body {

        margin-right: auto!important;
        margin-left: auto!important;
        max-width: 40%;
        @media screen and (min-width: 768px) {
          min-width: 700px;
        }
        @media screen and (max-width: 767px) {
          max-width: 90%;
          padding: 0;
        }
        .paddedInput {
          padding: 0 10px;
        }
        .alert {
          @media screen and (min-width: 768px) {
            font-size: 12px;
          }
          @media screen and (max-width: 767px) {
            font-size: 10px;
            padding: 10px;
          }
        }
        #invalidIbanAlertError {
          @media screen and (max-width: 768px) {
            font-size: 10px;            
          }
        }
        button {
          border-radius: 32px;
          position: absolute;
          @media screen and (min-width: 768px) {
            left: 50%;
            bottom: -50px;
            transform: translateX(-50%);
          }
          @media screen and (max-width: 767px) {
            bottom: 40px;
            left: 16px;
            width: calc(100% - 32px);
            height: 50px;
          }
        }
        form {
          width: 100%;
          height: calc(100vh - 90px);
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: center;
          @media screen and (max-width: 767px) {
            justify-content: flex-start;
          }
          .form_body {
            transform: translateY(-20%);
            position: relative;
            @media screen and (max-width: 767px) {
              height: calc(100vh - 180px);
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              padding-top: 15px;
              padding-bottom: 15px;
              transform: unset;
            }
            .iban_div {
              display: flex;
              width: 100%;
              .iban_inputs {
                margin-right: 5px;
              }
            }
          }
          .error-p {
            margin-bottom: 10px;
          }
          .success_msg {
            margin-bottom: 10px;
          }
          p {
            color: black;
            font-size: 16px;
            text-align: left;
          }
          input {
            margin-bottom: 20px;
            border-top: none;
            border-right: none;
            border-left: none;
            padding: 0;
            font-size: 18px;
            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: lightgrey;
              opacity: 1; /* Firefox */
            }
          }

          /* The checkbox_container */
          .checkbox_container {
            display: block;
            position: relative;
            padding-left: 35px;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 12px;
            @media screen and (max-width: 767px) {
              font-size: 10px;
            }
            font-weight: normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            text-align: justify;
          }

          /* Hide the browser's default checkbox */
          .checkbox_container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          /* Create a custom checkbox */
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            background-color: #eee;
          }

          /* On mouse-over, add a grey background color */
          .checkbox_container:hover input ~ .checkmark {
            background-color: #ccc;
          }

          /* When the checkbox is checked, add a blue background */
          .checkbox_container input:checked ~ .checkmark {
            background-color: $text-primary;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          .checkbox_container input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .checkbox_container .checkmark:after {
            left: 9px;
            top: 2px;
            width: 8px;
            height: 16px;
            border: solid white;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }
    }  
  }
}

#modalRibValidation {
  padding: 0 !important;
  line-height: normal;
  > .modal-dialog {
    width: 100%;
    margin: 0;
    height: 100%;
    background-color: $bg-primary;
    overflow: hidden;

    > .modal-content {
      height: 100%;
      background-color: $bg-primary;
      @media screen and (max-width: 767px) {
        background-color: white;
      }
      margin-top: 0;
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
      > .modal-header {
        border-bottom: none;
        .go_back_arrow {
          width: 60px;
          position: absolute;
          cursor: pointer;
          height: 60px;
          border-radius: 50%;
          background-image: url(/assets/common/arrow_blue.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      >.modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10vh;
        @media screen and (max-width: 767px) {
          height: calc(100vh - 180px);
          padding: 0;
        }
        button {
          border-radius: 32px;
          @media screen and (max-width: 767px) {
            height: 50px;
            width: 95%;
            position: absolute;
            bottom: 0;
          }
        }
        .error-p {
          margin-bottom: 10px;
        }
        .success_msg {
          margin-bottom: 10px;
        }
        .main_view {
          width: 60%;
          max-width: 821px;
          min-width: 320px;
          @media screen and (max-width: 767px) {
            width: 100%;
          }
          .rib_view {
            position: relative;
            display: flex;
            flex-direction: column;
            margin: 10px 10px;
            font-size: 20px;
            @media screen and (max-width: 767px) {
             font-size: 15px;
            }
            line-height: 26px;
            padding: 20px 35px;
            background-color: white;
            .row {
              &.heading {
                margin-bottom: 15px;
                .strong_div {
                  color: $text-secondary
                }
              }
            }
          }
          .rib_view_mobile {
            font-size: 16px;
            background-color: $bg-menu;
            height: 165px;
            width: 100%;
            text-align: center;
            margin-top: 20vh;
            padding-top: 35px;
            color: black;
            position: relative;
            .strong_div {
              font-size: 18px;
              color: $text-secondary
            }
            .owner {
              margin-bottom: 20px;
            }
            .signature_date {
              font-size: 12px;
            }
            .triangle {
              border-left: solid 21px transparent;
              border-top: solid 18px $bg-menu;
              border-right: solid 21px transparent;
              border-bottom: solid 18px transparent;
              height: 20px;
              position: absolute;
              left: calc(50% - 20px);
              bottom: -36px;
              width: 20px;
            }
          }
          .mandate_preview {
            color: $text-secondary;
            font-size: 16px;
            margin-right: 10px;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            @media screen and (max-width: 767px) {
              justify-content: center;
              margin-top: 30px;
            }
            .download_mandate {
              cursor: pointer;
              p {
                display: inline-block;
                margin-right: 10px;
                transform: translateY(25%);
              }
              img {
                width: 27px;
                height: 27px;
                display: inline-block;
                background-image: $gradient-red-short;
                border-radius: 50%;
                padding: 5px;
                cursor: pointer;
              }
            }
          }
          margin-bottom: 50px;        
        }
        p {
          font-size: 16px;
          margin-bottom: 20px;
        }
        .validation_warning {
          @media screen and (max-width: 767px) {
            text-align: center;
            font-size: 12px;
            max-width: 225px;
          }
        }
        input {
          margin-bottom: 20px;
          border-top: none;
          border-right: none;
          border-left: none;
          padding: 0;
          font-size: 18px;
          @media screen and (max-width: 767px) {
            font-size: 14px;
          }
          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: lightgrey;
            opacity: 1; /* Firefox */
          }
        }
        #signatureMissing {
          margin: 20px 0 -15px 0;
          text-align: center;
          @media screen and (max-width: 767px) {
            padding: 20px 35px;
            text-align: center;
            margin: 10px 10px -40px 10px;
          }
        }

        /* The checkbox_container */
        .checkbox_container {
          display: block;
          position: relative;
          padding: 20px 35px;
          cursor: pointer;
          margin: 10px;
          font-size: 14px;
          @media screen and (max-width: 767px) {
            font-size: 12px;
            margin: 20px;
          }
          font-weight: normal;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          text-align: justify;
        }

        /* Hide the browser's default checkbox */
        .checkbox_container input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          top: calc(50% - 25px);
          left: 0;
          height: 25px;
          width: 25px;
          background-color: #ccc;
        }

        /* On mouse-over, add a grey background color */
        .checkbox_container:hover input ~ .checkmark {
          background-color: #ccc;
        }

        /* When the checkbox is checked, add a blue background */
        .checkbox_container input:checked ~ .checkmark {
          background-color: $text-primary;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        .checkbox_container input:checked ~ .checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .checkbox_container .checkmark:after {
          left: 9px;
          top: 2px;
          width: 8px;
          height: 16px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
      }
    }
  }
}