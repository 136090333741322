.body_topnav.nav_desktop {
    min-height: 70px;
    border: 0;
    background-color: #F7F7F7;
    margin: 0px;
    border-radius: 0px;
    &.navbar-inverse {
      .navbar-right > li > a {
        box-shadow: none;
        text-align: left;
        font-size: 16px;
        background-image: url("/assets/common/user_ico.svg");
        background-size: 42px;
        background-repeat: no-repeat;
        background-position: left 15px;
        height: 70px;
        padding-left: 50px;
        padding-top: 15px;
        padding-bottom: 0px;
        background-color: unset;
      }
      .navbar-right > li > a > span {
        color: #000000;
        &.nav-first-name{
          color: #9d9d9d;
        }
      }
      .navbar-nav > {
        .open > a {
          &:focus, &:hover {
            background-color: unset;
          }
        }
        li > a {
          color: #9d9d9d;
          &:focus, &:hover {
            color: #9d9d9d;
          }
        }
        .open > a {
          color: #9d9d9d;
          &:focus, &:hover {
            color: #9d9d9d;
          }
        }
      }
    }
  }

  /* .nav_desktop.navbar-inverse .navbar-right>li>a:hover,
  .nav_desktop.navbar-inverse .navbar-right>li>a:focus {
    color: #9d9d9d
  } */

  @media screen and (min-width: 768px) {
    .nav_mobile {
      display: none;
    }
    .navbar-brand {
      background-image: url("/specific/logo/logo.png");
      background-size: 165px;
      height: 69px;
      width: 190px;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: auto;
      background-position: 25px 15px;
    }
  }

  @media screen and (max-width: 767px) {
    .nav_desktop {
      display: none;
    }
  }
