/*
 * File: _variables.scss
 * File Created: Thursday, 14th February 2019 11:36:06 am
 * Author: Paul Mazeau (paul.mazeau@gridpocket.com)
 * -----
 * Last Modified: Thursday, 1st August 2019 4:37:35 pm
 * Modified By: Pierre Bonny (pierre.bonny@gridpocket.com>)
 * -----
 * GridPocket SAS Copyright (C) 2019 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */

.loader-3-dots {
    top: calc(50% - 130px);
    left: calc(50% + 110px);
    @media screen and (max-width: 991px) and (min-width: 768px) {
        left: calc(50% + 60px);
    }
    transform: translate(-50%, -50%);
    &.inModal {
        top: calc(50% - 20px);
        left: calc(50% - 25px);
    }
    position: absolute;
    z-index: 8;
    @include fadeIn($duration: 0.4s, $function: ease-in-out, $delay: 0s);
    .desktop-loader {
        display: flex;
        flex-direction: row;
        .dot {
            background-color: $text-secondary;
            width: 12px;
            height: 12px;
            margin: 0 5px;
            border-radius: 100px;
            animation: 2s dot3Bouncing ease-in-out infinite;
            &:nth-of-type(2) {
                animation: 2s dot3Bouncing ease-in-out infinite;
                animation-delay: .1s;
            }
            &:last-of-type {
                animation: 2s dot3Bouncing ease-in-out infinite;
                animation-delay: .2s;
            }
        }
    }
    .mobile-loader {
        display: none;
    }
    @media screen and (max-width: 768px) and (min-width: 480px) {
        left: calc(50%);
        .desktop-loader {
            display: none;
        }
        .mobile-loader {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            position: relative;
            align-items: center;
            .dot {
                background-color: $text-secondary;
                margin: 0 5px;
                border-radius: 100px;
                transform-origin: center;
            }
            .dot-1 {
                position: relative;
                width: 40px;
                height: 40px;
                animation: dot2Bouncing1 2s infinite;
                background-color: rgba($text-secondary, .2);
            }
            .dot-2 {
                position: absolute;
                width: 15px;
                height: 15px;
                right: 32.5px;
                animation: dot2Bouncing2 2s infinite;
                background-color: $text-secondary;
            }
        }
    }
    @media screen and (max-width: 480px) {
        left: calc(50%);
        .desktop-loader {
            display: none;
        }
        .mobile-loader {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            position: relative;
            align-items: center;
            .dot {
                background-color: $text-secondary;
                margin: 0 5px;
                border-radius: 100px;
                transform-origin: center;
            }
            .dot-1 {
                position: relative;
                width: 40px;
                height: 40px;
                animation: dot2Bouncing1 2s infinite;
                background-color: rgba($text-secondary, .2);            }
            .dot-2 {
                position: absolute;
                width: 15px;
                height: 15px;
                right: 27px;
                animation: dot2Bouncing2 2s infinite;
                background-color: $text-secondary;            }
        }
    }
    @keyframes dot3Bouncing {
        0% {
            transform: translateY(0px);
        }
        25% {
            transform: translateY(5px);
        }
        50% {
            transform: translateY(-5px);
        }
        75% {
            transform: translateY(5px);
        }
        100% {
            transform: translateY(0px);
        }
    }
    @keyframes dot2Bouncing1 {
        0% {
            transform: scale(0);
        }
        40% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes dot2Bouncing2 {
        0% {
            transform: scale(0);
        }
        20% {
            transform: scale(1.2);
        }
        23%{
            transform: scale(.9);
        }
        26%{
            transform: scale(1.1);
        }
        29%{
            transform: scale(.95);
        }
        32%{
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
}

#loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
    text-align: center;
  }