#add-admin-modal {
  background-color:rgba(0,0,0,0.3);
  label{
    min-width: 110px;
  }
  input{
    min-width: 200px;
  }

  .field-icon {
    margin-left: -30px;
    margin-top: 2px;
    position: absolute;
    z-index: 3;
    width: 25px;
    height: 20px;
    cursor: pointer;
  }

}

.modal-body {
  .modal-alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
}

.edit-admin-modal{
  .modal-body{
    text-align: left;
    label{
      min-width: 110px;
    }
    .input-text{
      min-width: 200px;
    }
    .edit-admin-role{
      font-weight: bold;
      margin: 0 0 0;
    }
    .role-select{
      margin-left: 30px;
    }
  }
}

.body_admin_settings {
  #add-admin-btn{
    margin-bottom: 10px;
  }

  #search{
    display: none;
  }
}


.delete-admin-modal{
  .delete-warning{
    font-weight: bold;
  }
  .btn.btn-primary {
    background-image:linear-gradient(to right, #d04e4b, #C83257);
  }
}

#add-admin-group {
  height: 25px;
  min-width: 200px;
}
#add-admin-group-label {
  margin-left:115px;
}

#add-admin-password {
  position: relative;
}