/* TO BE TESTED AND SHOULD PROBABLY BE REMOVED */
@media (min-width: 992px) and (max-width: 1199px) {
  .form-control {
    height: 34px;
  }
}

.alt-center-block {
  display: table;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (max-width: 767px) {
  body {
    color: #666;
    .body_content .main {
      // padding-top: 45px; //Position of top-menu is now relative should be useless
    }
  }

}

.body_flex {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .full_height_content {
    display: flex;
    flex: 0 0 auto; 
    min-height: calc(100vh - 70px - 220px);
  }
}

#modal-change-password-view .field-icon {
  float: right;
  margin-right: 10px;
  margin-top: -26px;
  position: relative;
  z-index: 2;
  width: 25px;
  height: 20px;
  cursor: pointer;
}

h1, h2, h3 {
  color: $text-title;
}

h1, h2 {
  font-size: 26px;
  line-height: 1.1;
}

h3 {
  font-size: 20px;
}

.alert {
  display: none;
}
#modal-contact-view .alert {
  display: none;
}

.logo {
  margin-bottom: 10px;
}

/* button global style */
.error-p {
  color: $text-primary;
  display: none;
  font-family: $first-font;
  font-size: 12px;
  text-align: left;
  line-height: 14px;
  font-weight: normal;
}
.exclamation-circle-icon {
  margin-right: 5px;
  width: 18px;
}

.btn {
  position: relative;
  border: none;
  border-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
  height: 34px;
  &:focus, &:active {
    outline: none;
  }
  &.btn-lg {
    padding: 13px 16px;
    font-size: 18px;
    font-weight: 600;
  }
  &.btn-default {
    color: #666;
    background-size: 300% 100%;
    background-color: unset;
    font-size: 15px;
    font-weight: 600;
    border-radius: 30px;
  }
  &.btn-primary {
    color: #fff;
    background-size: 300% 100%;
    background-image: $gradient-red;
    &.static{
      cursor: none;
      pointer-events: none;
    }
    &.static:hover{
      background-position: initial;
      transition: unset;
    }
    &:hover {
      background-position: 100% 0;
      transition: all .4s ease-in-out;
    }
  }
  &.btn-secondary {
    width: unset;
    background-color: #ebe8e5;
    border-radius: 20px;
    padding: 5px 15px;
    text-decoration-line: unset;
    &:hover {
      background-position: 100% 0;
      transition: all .4s ease-in-out;
    }
  }
  &.btn-white {
    width: unset;
    background-color: #ffffff;
    border-radius: 20px;
    text-decoration-line: unset;
    font-size: 16px;
    color: $text-secondary;
    &.chevron {
      background-image: url("/assets/common/chevron_blue.svg");
    }
  }
  &.btn-grey {
    width: unset;
    background-color: #ebe8e4;
    border-radius: 20px;
    text-decoration-line: unset;
    font-size: 16px;
    color: $text-secondary;
  }
  &.chevron {
    background-size: 8px;
    background-repeat: no-repeat;
    background-position: center right 20px;
    padding-right: 45px;
    &:hover {
      background-position: center right 18px;
      transition: all .1s ease-in-out;
    }
  }
}

.btn-group {
  height: 100%;
  color: #666;
  background-size: 300% 100%;
  background-color: #ebebeb;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  border-radius: 30px;
  padding: 3px;
  .btn.btn-default-toggle {
    background: unset;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    transition: none;
    border-radius: 30px;
  }
}

.btn.btn-default.active, .btn-group:hover .btn.btn-default:hover, .btn.btn-default-toggle.active {
  box-shadow: unset;
  color: #fff;
  background-color: #c84257;
}

.btn-group {
  &:hover .btn {
    &.btn-default-toggle:hover {
      box-shadow: unset;
      color: #fff;
      background-color: #c84257;
    }
    &.btn-default, &.btn-default-toggle {
      background-color: #ebebeb;
      color: #666;
    }
  }
  > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-radius: inherit;
  }
  > {
    .btn {
      &:first-child:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: inherit;
        border-bottom-right-radius: inherit;
      }
      &:last-child:not(:first-child) {
        border-top-left-radius: inherit;
        border-bottom-left-radius: inherit;
      }
    }
    .dropdown-toggle:not(:first-child) {
      border-top-left-radius: inherit;
      border-bottom-left-radius: inherit;
    }
  }
}

/* Uppercase first letter */

span.uppercase-first {
  display: inline-block;
  &:first-letter {
    text-transform: uppercase !important;
  }
}

/*== MODALS ==*/

.modal.modal_default {
  .modal-content {
    border: none;
    border-radius: 0;
  }
  .modal-header .close {
    opacity: 1;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
    top: 15px;
    // &:after {
    //   content: '';
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   background-image: url(https://localhost/images/common/close_blue_ico.svg);
    //   background-size: 16px;
    //   background-repeat: no-repeat;
    //   background-position: center center;
    // }
  }
}


#modal-contact-view {
  .modal-title {
    font-size: 26px;
  }
  .modal-footer .btn-primary {
    color: #fff;
    background-size: 300% 100%;
    background-image: $gradient-red;
    border-radius: 20px;
    margin-right: 5px;
    padding: 6px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

/* Modal Contract */

.modal_contract .modal-content {
  border: none;
  border-radius: 0;
  display: table;
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  min-height: 100%;
  button {
    width: 100%;
    + button {
      margin-top: 15px;
    }
    .criteria {
      margin-top: 4px;
      .item {
        display: inline-block;
        background-color: white;
        color: $text-primary;
        font-size: 12px;
        padding: 3px 6px;
        font-weight: 700;
        position: relative;
        &.actif {
          padding-right: 25px;
          &:after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #5cb85c;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
            border-radius: 50%;
          }
        }
        &.cesse {
          padding-right: 25px;
          &:after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: #f0ad4e;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
            border-radius: 50%;
          }
        }
        &.cancel {
          padding-right: 25px;
          &:after {
            content: '';
            position: absolute;
            display: inline-block;
            width: 12px;
            height: 12px;
            background-color: $text-primary;
            top: 50%;
            right: 7px;
            transform: translateY(-50%);
            border-radius: 50%;
          }
        }
      }
    }
  }
}

/*== EMPTY DASHBOARD ANNONCE ==*/

.empty_dashboard_annonce {
  background-color: #fff;
  .explanation {
    text-align: center;
    padding: 30px 30px 0;
    color: #666;
    h3 {
      font-size: 26px;
      margin-top: 0;
      margin-bottom: 20px;
      color: #666;
    }
    p {
      font-size: 17px;
      line-height: 1.1;
      a {
        color: #666;
        text-decoration: underline;
        transition: 0.2s;
        &:hover {
          color: #fa6b66;
        }
      }
    }
    .btn {
      margin-top: 30px;
      margin-bottom: 20px;
      min-width: 200px;
    }
  }
  .optional_bloc {
    min-height: 338px;
    background-color: #fff;
  }
  .soon {
    display: table;
    margin: 0 auto;
    position: relative;
    bottom: 0;
    transform: translateY(50%);
    p {
      background-color: white;
      color: $text-secondary;
      font-weight: 700;
      padding: 20px 25px;
      margin-bottom: 0;
      box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.3);
      position: relative;
      z-index: 10;
    }
    .decoration {
      position: absolute;
      background-color: white;
      height: 100%;
      width: calc(100% - 20px);
      top: 0;
      left: 10px;
      transform: rotate(-6deg);
      box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.3);
      opacity: .5;
    }
  }
  .arguments_bloc {
    background-color: cyan;
    padding: 60px 30px 0;
    color: white;
    font-size: 0;
    background: #6f46cf;
    background: -moz-linear-gradient(left, #6f46cf 0%, #3f6bb6 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, #6f46cf), color-stop(100%, #3f6bb6));
    background: -webkit-linear-gradient(left, #6f46cf 0%, #3f6bb6 100%);
    background: -o-linear-gradient(left, #6f46cf 0%, #3f6bb6 100%);
    background: -ms-linear-gradient(left, #6f46cf 0%, #3f6bb6 100%);
    background: linear-gradient(to right, #6f46cf 0%, #3f6bb6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6f46cf', endColorstr='#3f6bb6', GradientType=1);
    h3 {
      font-size: 24px;
      text-transform: uppercase;
      margin-top: 0;
      color: white;
    }
    .slick-slider .item .summary {
      font-size: 16px;
    }
    .picture {
      max-width: 100%;
    }
    .slick-dotted.slick-slider {
      margin-bottom: 0;
    }
    .slider {
      .slick-arrow {
        visibility: hidden;
      }
      .slick-dots {
        width: auto;
        left: calc(25% + (75% / 2) + 30px);
        transform: translateX(-50%);
        bottom: 50px;
        li {
          margin: 0 8px;
          button::before {
            content: '';
            opacity: 1;
            background-color: white;
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
          &.slick-active button::before {
            background-color: #313772;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .empty_dashboard_annonce .arguments_bloc .slider .slick-dots {
    bottom: 15px;
  }
}

@media screen and (max-width: 767px) {
  .empty_dashboard_annonce .arguments_bloc {
    padding-bottom: 5px;
    .slick-dotted.slick-slider {
      margin-bottom: 15px;
      margin-top: 15px;
    }
    .slider {
      margin-bottom: 15px;
      .slick-dots {
        position: relative;
        left: 50%;
        bottom: -5px;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .empty_dashboard_annonce {
    .explanation {
      padding: 30px 15px 0;
      h3 {
        font-size: 22px;
      }
    }
    .soon p {
      padding: 15px;
    }
    .arguments_bloc {
      padding: 50px 15px 5px;
      h3 {
        font-size: 22px;
      }
    }
  }
}

body{
  font-family: "Roboto" !important;
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-family: "Roboto" !important;
  }
}

$spacer: 1rem;

// Margin helpers
.mt-1 {
  margin-top: ($spacer * .25) !important;
}

.mt-2 {
  margin-top: ($spacer * .5) !important;
}

.mt-3 {
  margin-top: $spacer !important;
}

.mt-4 {
  margin-top: ($spacer * 1.5) !important;
}

.mt-5 {
  margin-top: ($spacer * 2) !important;
}

.mt-6 {
  margin-top: ($spacer * 3) !important;
}
