@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i,700,700i');

.body_signin {
  /*====================
  GLOBAL   
  ===================*/
  
  .content_bloc {
    box-shadow: none;
    padding: 0px;
    margin-bottom: 0;
  }
  
  .create-ico {
    width: 33px;
    margin-left: 10px;
  }
  
  .clearfix.lost_password {
    float: right;
  }
  
  .checkbox {
    font-family: $first-font;
    font-size: 12px;
    color: rgba(136, 136, 136, 1);
    line-height: 14px;
    font-weight: normal;
    margin-top: 18px;
    margin-left: 15px;
  }
  
  /*====================
  BUTTONS   
  ===================*/

  .btn {
    &.btn-primary {
      &.plug_ico {
        padding-right: 65px;
        padding-left: 65px;
        &:after {
          @include buttons;
          background-image: url(/images/common/plug_circle_ico.svg);
        }
        .fa-pulse {
          margin-left: 5px;
        }
      }
      &.user_ico {
        padding-right: 65px;
        padding-left: 65px;
        &:after {
          @include buttons;
          background-image: url(/images/common/user_circle_ico.svg);
        }
      }
    }
    &.btn-secondary {
      &.plug_ico {
        padding-right: 65px;
        padding-left: 65px;
        &:after {
          @include buttons;
          background-image: url(/images/common/plug_circle_ico.svg);
        }
      }
      &.user_ico {
        padding-right: 65px;
        padding-left: 65px;
        &:after {
          @include buttons;
          background-image: url(/images/common/user_circle_ico.svg);
        }
      }
    }
  }
  
  /*========================
  LOGIN PAGE   
  =========================*/
  
  @media screen and (max-width: 480px) {
    .body_public {
      background-position: right center;
    }
    background-position:  right -100px bottom -200px;
    min-height: 500px;
    .login_page {
      display: none;
    }
  }
  
  
  .dot {
    cursor: pointer;
    height: 10px;
    width: 10px;
    margin: 0 10px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    &.active, &:hover {
      background-color: $text-primary;
    }
  }
  
  .signin-form {
    padding-bottom: 25px;
    margin-bottom: 35px;
    border-bottom: solid 1px lightgrey;
  }

  #master_consumption {
    color: rgba(200, 50, 87, 1);
    text-transform: uppercase;
    margin-bottom: 50px;
    font-size: 26px;
    text-align: center;
    font-weight: normal;
    @media (max-width: 991px) {
      font-size: 18px;
    }
    @media (max-width: 832px) {
      font-size: 16px;
    }
  }
  
  /*========================
  LOGIN MOBILE   
  =========================*/
  
  .login_mobile {
    display: none;
    width: 100%;
    overflow: hidden;
    height: 100%;
    color: #fff;
    text-align: center;
    header {
      padding: 40px 0 0;
      margin-bottom: 70px;
      position: relative;
      padding: 30px 0 0;
      margin-bottom: 90px;
      .content_bloc {
        position: relative;
        z-index: 2;
        .mobile_label {
          font-family: $first-font;
          font-size: 16px;
          color: rgba(112, 112, 112, 1);
          text-align: center;
          line-height: 19px;
        }
      }
      .logo_mynexio {
        width: 180px;
        margin-bottom: 15px;
      }
      h2 {
        color: rgba(200, 50, 87, 1);
        text-transform: uppercase;
        margin-bottom: 50px;
        font-family: $first-font;
        font-size: 16px;
        text-align: center;
        line-height: 19px;
        font-weight: normal;
      }
      .rounded_border {
        background-image: url(/specific/background/introduction_bg.jpg);
        background-size: cover;
        background-position: right bottom;
        height: 320px;
        bottom: -50px;
        width: 170%;
        border-bottom-left-radius: 60%;
        border-bottom-right-radius: 60%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
    }

    .main_content {
      padding: 0 30px 15px;
      .blockquote {
        font-size: 16px;
        margin-bottom: 10px;
        min-height: 75px;
      }
      button {
        width: 100%;
      }
      .register_account {
        margin-top: 10px;
        .link_primary {
          color: #fff;
          font-size: 14px;
        }
      }
    }
    footer {
      width: 1030px;
      max-width: 100%;
      margin: 0 auto;
      font-size: 0;
      position: absolute;
      bottom: 0;
      padding: 0 30px 15px;
      .smeg {
        display: inline-block;
        vertical-align: bottom;
        width: 30%;
        text-align: left;
        img {
          width: 50px;
        }
      }
      .gridpocket {
        display: inline-block;
        vertical-align: bottom;
        width: 70%;
        font-size: 14px;
        text-align: right;
        p {
          margin-bottom: 0;
          color: #fff;
        }
      }
    }
  }
  
  .modal_login {
    .modal-dialog {
      margin: 0;
      min-height: 100%;
      height: auto;
      display: absolute;
    }
    .modal-content {
      color: #666;
      min-height: 100%;
      height: auto;
      text-align: center;
      border: none;
      border-radius: 0;
      position: absolute;
      width: 100%;
      .modal-header {
        color: $text-primary;
        text-transform: uppercase;
        font-size: 18px;
        border: none;
        padding-top: 35px;
        .logo_mynexio {
          width: 180px;
          margin-bottom: 15px;
        }
      }
      form button {
        width: 100%;
        margin-bottom: 15px;
        margin-top: 30px;
      }
      .lost_password {
        text-align: right;
        a {
          color: #666;
        }
      }
    }
    .close {
      opacity: 1;
      background-color: red;
      height: 33px;
      width: 33px;
      border-radius: 50%;
      background-image: linear-gradient(to right, #783cd5, $text-secondary);
      position: absolute;
      right: 15px;
      top: 15px;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(/images/common/cancel_white_ico.svg);
        background-size: 16px;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
  
  .login_mobile {
    .main_content .quote_bloc {
      min-height: 100px;
    }
    .modal_login .modal-content .create_account_btn {
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 20px 16px;
      position: absolute;
    }
  }
  
  .footer_mobile {
    font-size: 0;
    padding: 0 30px 15px;
    display: none;
    width: 100%;
    height: 80px;
    .smeg {
      display: inline-block;
      vertical-align: bottom;
      width: 30%;
      text-align: left;
      img {
        width: 50px;
      }
    }
    .gridpocket {
      display: inline-block;
      vertical-align: bottom;
      width: 70%;
      font-size: 14px;
      text-align: right;
      p {
        margin-bottom: 0;
        color: #fff;
      }
    }
  }
  
  body.apple-ios.modal-open {
    position: fixed;
    width: 100%;
  }
  
  @media screen and (max-width: 480px) {
    .body_public {
      position: relative;
      min-height: 100vh;
      padding-bottom: 80px;
      height: auto;
    }
    .footer_mobile, .login_mobile {
      display: block;
    }
  }
  
  @media only screen and (max-device-width: 991px) and (orientation: landscape) {
    .modal-backdrop {
      &.in {
        opacity: 0;
      }
      display: none;
    }
    body.modal-open {
      overflow: auto;
    }
  }
  
  @media screen and (max-width: 310px) {
    .login_mobile .main_content .quote_bloc {
      display: none;
    }
  }
  
  i#loading-icon {
    color: #000;
    position: absolute;
    z-index: 999;
    top: 50%;
    left: 50%;
    font-size: 100px;
    margin-top: -50px;
    margin-left: -50px;
  }

  .m-connect-container {
    display: flex;
    width: 100%;
    margin-top: 30px;
    justify-content: center;
    padding: 20px;

    a {
      cursor: pointer;
    }
  }
}
