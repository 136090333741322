@import "./invoices/ribs_management.scss";

.body_invoice {
  @mixin buttonsBefore($class, $url, $left) {
    &#{$class}:before {
      content: "";
      position: absolute;
      left: $left;
      top: 0;
      bottom: 0;
      width: 22px;
      background: $url (center / contain) no-repeat;
    }
  }

  @mixin buttonsStyle() {
    .btn {
      cursor: pointer;
      &.btn-primary {
        color: #fff;
        background-size: 300% 100%;
        background-image: $gradient-red;
        border-radius: 20px;
      }
      &.btn-secondary {
        color: #fff;
        background-size: 300% 100%;
        background-image: $gradient-bright-bg1;
      }
      &.btn-tertiary {
        color: $text-title;
        background-color: white;
        border-radius: 20px;
      }
    }
  }
  .popover_debit_help {
    background-color: white;
    border-color: white;
    color: #666666;
    .arrow::after {
      border-top-color: white!important;
    }
    .arrow {
      border-top-color: white!important;
    }
  }

  .body_topnav.nav_desktop {
    background-color: $bg-menu;
  }

  background-color: $bg-primary;
  h2 {
    font-size: 15px;
  }

  footer {
    width: auto;
  }

  .padding-content {
    padding-right: 25px;
    padding-left: 25px;
    margin-right: auto;
    margin-left: auto;
  }

  .main.contracts_view {
    background-color: $bg-primary;
    font-family: $first-font;
    .darkFilter {
      height: 100%;
      width: 100%;
      z-index: 10;
      background-color: rgba(0,0,0,0.5);
      position: fixed;
      top: 45px;
      .close_dark_filter {
        position: absolute;
        width: 33px;
        height: 33px;
        right: 20px;
        top: 20px;
      }
    }
    .titleContainer {
      width: 100%;
      margin-bottom: 99px;
      &.sliders {
        margin-bottom: 49px;
      }
      &.sliderAll {
        margin-bottom: 0;
      }
      .title {
        margin: 0;
        text-align: center;
        font-size: 44px;
        color: $text-secondary;
        margin-top: 50px;
      }
    }
    .no_display {
      text-align: center;
      margin-top: 100px;
    }
    .invoices-display-header {
      padding-right: 15px;
      margin-bottom: 30px;
      width: 100%;
      .head {
        color: $text-title;
        font-family: $first-font;
        font-size: 26px;
        &.allFactures {
          padding-bottom: 38px;
        }
        &.none {
          display: none;
        }
      }
      .infos {
        margin-bottom: 20px;
        margin-right: 460px;
        .numbers {
          font-size: 26px;
          color: $text-title;
          line-height: 32px;
          align-items: center;
          margin-bottom: 8px;
          .contract_number {
            white-space: nowrap;
            font-weight: bold;
            padding-right: 26.5px;
            border-right: solid 1px #666666;
          }
          @media screen and (max-width : 991px) {
            .contract_number {
              border-right: solid 0px #666666;
            }
          }
          .edl_number {
            white-space: nowrap;
            padding-left: 26.5px;
          }
          .user_roles {
            padding-left: 26.5px;
            padding-right: 26.5px;
            border-right: solid 1px #666666;
          }
          .energy_type_ico {
            margin-right: 5px;
            width: 23px;
          }
          .user_role {
            font-size: 16px;
            color: #666666;
          }
        }
        .right_details {
          display: flex;
          font-size: 16px;
          color: #666666;
          line-height: 19px;
          width: 100%;
          justify-content: flex-start;
          .address {
            padding-right: 10px;
            border-right: solid 1px #666666;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: calc(100% - 202px);
            overflow: hidden;
            position: relative;
            padding-left: 28px;
            &:before {
              content: "";
              position: absolute;
              left: 0px;
              top: 0;
              bottom: 0;
              width: 23px;
              background: url(/assets/invoice/place.png) (center / contain) no-repeat;
            }
          }
          .active_date {
            padding-left: 10px;
          }
        }
      }

      .buttons {
        .btn {
          margin-bottom: 10px;
          cursor: pointer;
          padding-right: 12px;
          padding-left: 35px;
          &.btn-primary {
            margin-right: 5px;
            @include buttonsBefore(".debit", url(/assets/invoice/transfert_white.png), 8px);
            @include buttonsBefore(".efacture", url(/assets/invoice/efacture.png), 10px);
          }
          &.btn-secondary {
            margin-right: 5px;
          }
          &.btn-tertiary {
            margin-right: 5px;
            @include buttonsBefore(".modify_address", url(/assets/invoice/edit.png), 10px);
            @include buttonsBefore(".manage_rib", url(/assets/invoice/transfert.png), 10px);
          }
        }
        .more-infos {
          height: 22px;
          margin-right: 5px;
          cursor: pointer;
        }
        @include buttonsStyle();
      }
    }

    @mixin invoices() {
      position: relative;
      flex-flow: wrap;
    }
    .main-view {
      animation-duration: 0.8s;
      border-bottom: solid 1px rgba(102,102,102,0.15);
      &.all {
        border-bottom: none;
      }
    }
    .slider-invoices {
      max-width: 1360px;
      padding-bottom: 50px;
      padding-top: 50px;
      margin: 0 auto;
      padding-left: 15px;
      padding-right: 15px;

      &.none {
        display: none;
      }
      &.bgWhite {
        padding-top: 131px;
      }

      .controls {
        margin: 0;
        padding: 0;
        list-style: none;
        left: -30px;
        right: 0;
        background: transparent;
        top: calc(50% - 40px);
        display: flex;
        position: absolute;
        justify-content: space-between;
        z-index: 3;
        &:focus {
            outline: none;
        }
        .arrow.none {
          display: none;
        }
        &.all {
          right: 230px;
        }
      }

      .cont {
        width: 230px;
        position: relative;
        margin-left: 15px;
        &.none {
          display: none;
        }
        .unpayed-total {
          margin-top: -6px;
          display: flex;
          flex-direction: column;
          font-size: 26px;
          font-family: $first-font;
          color: $text-title;
          .amount {
            font-weight: bold;
            white-space: nowrap;
          }
          .btn.btn-tertiary {
            background-color: white;
            border-radius: 20px;
            color: $text-title;
            margin-right: 5px;
            cursor: pointer;
            padding-right: 12px;
            padding-left: 37px;
            font-size: 16px;
            @include buttonsBefore(".pay_integrality_cb", url(/assets/invoice/cb.png), 15px);
          }
          .pay_integrality_cb + .tooltip > .tooltip-arrow {
            border-bottom-color: $text-title;
          }
          .pay_integrality_cb + .tooltip > .tooltip-inner {
            background-color: $bg-menu;
            border: solid 1px $text-title;
            font-family: $first-font;
            font-size: 16px;
            color: $text-title;
          }
          .pay_integrality_cb + .tooltip {
            opacity: 1.0;
          }
        }
      }

      .all_container {
        display: flex;
        .tns-outer {
          @media screen and (min-width: 1510px) {
            width: calc((100% / 4) * 3);
          }
          @media screen and (max-width: 1510px) {
            width: calc((100% / 3) * 2);
          }
          @media screen and (max-width: 1199px) {
            width: calc(100% / 2);
          }
        }
      }

      .tns-outer {
        position: relative;
        .tns-controls {
          position: absolute;
          z-index: 10;
          width: 100%;
          button {
            &.none {
              display: none;
            }
            height: 216px;
            width: 30px;
            position: absolute;
            background-color: transparent;
            border: none;
            padding: 0;
            background-size: 15px 24px;
            background-repeat: no-repeat;
            background-position: center center;
          }
          [data-controls="prev"] {
            background-image: url("/assets/invoice/nav_left.png");
            left: -15px;
          }
          [data-controls="next"] {
            background-image: url("/assets/invoice/nav_right.png");
            right: -15px;
          }
        }
      }

      .all_container .tns-controls button {
        height: 312px;
      }


    }
      .slider-invoices-display {
        display: flex;
        position: relative;
        .subdiv {
          padding: 0 15px;
          padding-top: 15px;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

    .contract-div {
      max-width: 1360px;
      width: 100%;
      padding-top: 52px;
      margin-left: auto;
      margin-right: auto;
      &.centered {
        margin: 0 auto;
        padding: 0 15px;
      }
      .subdiv {
        padding: 0 15px;
        display: inline-block;
        margin-bottom: 52px;
      }
      // @media screen and (max-width: 768px) {
      //   display: none;
      // }
      @media screen and (max-width: 1199px) {
        .subdiv {
          width: calc(100% / 2);
        }
      }
      @media screen and (max-width: 1510px) and (min-width: 1199px) {
        .subdiv {
          width: calc(100% / 3);
        }
      }
      @media screen and (min-width: 1510px) {
        .subdiv {
          width: calc(100% / 4);
        }
      }
      &.none {
        display: none;
      }
      @include invoices();
      margin-bottom: 160px;
    }

    @mixin invoicesBefore($class, $color, $size) {
      &#{$class} {
        border-left: $size solid $color;
        border-top: $size solid $color;
      }
    }

    .invoice_view {
      position: relative;
      transition: .2s ease-in-out;
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px;
      line-height: normal;
      .triangle {
        @include invoicesBefore(".unpayed", $red-indicator, 34px);
        @include invoicesBefore(".unpayed_s", $red-indicator, 34px);
        @include invoicesBefore(".payed", $green-indicator, 34px);
        @include invoicesBefore(".pending", $orange-indicator, 34px);
        border-bottom: 34px solid transparent;
        border-right: 34px solid transparent;
        height: 0;
        width: 0;
        position: absolute;
      }
      &.none {
        display: none;
      }
      &:hover {
        cursor: pointer;
        .invoice_bottom_payed {
          border-top-color: $bg-primary;
        }
        .invoice_bottom_payed,
        .invoice_body {
          background-color: $bg-menu;
        }
      }

      .invoice_body {
        transition: .2s ease-in-out;
        background-color: white;
        color: #666666;
        display: flex;
        flex-direction: column;
        padding-top: 29px;
        .invoice_price {
          color: $text-title;
          font-size: 26px;
          text-align: center;
          .tax_sentence{
            font-size:16px;
            vertical-align:baseline;
          }
          &.bold {
            font-weight: bold;
          }
        }
        .invoice_details {
          text-align: center;
          font-size: 16px;
          &.unpayed {
            padding-bottom: 20px;
          }
          &.unpayed_s {
            padding-bottom: 20px;
          }
          &.payed {
            padding-bottom: 40px;
          }
          &.pending {
            padding-bottom: 37px;
          }
          padding-bottom: 20px;
          padding-top: 20px;
          .italic_details {
            font-style: italic;
          }
        }
        .failurepayment{
          color: $primary-color;
        }
        .contract_details {
          font-size: 16px;
          padding-left: 20px;
          padding-top: 20px;
          padding-bottom: 20px;
          border-top: solid $bg-menu 1px;
          .contract_details_position {
            position: relative;
            height: 17px;
            padding-bottom: 20px;
            .addressText {
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 240px;
              overflow: hidden;
              display: inline-block;
              padding-left: 15px;
            }
            &:before {
              content: "";
              position: absolute;
              left: -5px;
              top: 0;
              bottom: 0;
              width: 20px;
              height: 20px;
              background: url(/assets/invoice/place.png) (center / contain) no-repeat;
            }
          }
        }
      }
      .invoice_bottom_unpayed {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55px;
        background-size: 300% 100%;
        background-image: $gradient-red;
        border-bottom-left-radius:5px;
        border-bottom-right-radius:5px;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 16px;
        color: white;
        position: relative;
        .fa-question-circle {
          position: absolute;
          top: 10px;
          right: 10px;
          margin-left: 80px;
          font-size: 24px;
          color: white;
        }
        [class^="invoice_"] {
          color: $text-quinary;
          font-weight: bold;
          font-size: 16px;
          position: relative;
          left: 12.5px;
          &[class$="debit"] {
            &:before {
              content: "";
              position: absolute;
              left: -30px;
              top: -2px;
              bottom: 0;
              width: 22px;
              height: 22px;
              background: url(/assets/invoice/transfert_white.png) (center / contain) no-repeat;
            }
          }

        }
      }
      .invoice_bottom_payed {
        transition: .2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        height: 55px;
        border-top: solid $bg-menu 1px;
        color: #666666;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      .invoice_bottom_failure {
        transition: .2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        height: 55px;
        border-top: solid $bg-menu 1px;
        color: $primary-color;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      .invoice_bottom_pending {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 55px;
        background-color: $orange-indicator;
        border-bottom-left-radius:5px;
        border-bottom-right-radius:5px;
        margin-bottom: 5px;
        color: $text-quinary;
        font-weight: bold;
        font-size: 16px;
      }
    }

    .invoice_footer {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      color: #666666;
      .pay_by_cb {
        position: relative;
        left: 25px;
        cursor: pointer;
        width: fit-content;
        &:before {
          content: "";
          position: absolute;
          left: -25px;
          top: 0;
          bottom: 0;
          width: 20px;
          background: url(/assets/invoice/cb_grey.png) (center / contain) no-repeat;
        }
      }
      .flex_line {
        display: flex;
        justify-content: space-between;
        .download_invoice {
          position: relative;
          left: 25px;
          cursor: pointer;
          width: fit-content;
          .fa-spinner {
            margin-left: 2px;
          }
          &:before {
            content: "";
            position: absolute;
            left: -25px;
            top: 0;
            bottom: 0;
            width: 20px;
            background: url(/assets/invoice/dl.png) (center / contain) no-repeat;
          }
        }
        .label {
          color: $text-quinary;
          font-size: 90%;
          border-radius: 0;
          font-weight: normal;
          background-image: $gradient-bright-bg1;
        }
      }
    }
    .payement_div {
      width: 100%;
      background-image: $gradient-bright-bg;
      position: fixed;
      bottom: 0;
      box-shadow: -5px -5px 20px 0px rgba(0, 0, 0, 0.16);
      z-index: 20;
      &.small {
        padding: 31px 0;
      }
      .total_div {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: normal;
        justify-content: center;
        color: white;
        .total {
          font-weight: bold;
          margin-left: 3px;
        }
      }
      .button_div {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        .btn.btn-tertiary {
          width: 80%;
          font-size: 14px;
          @include buttonsBefore('.pay_integrality_cb', url(/assets/invoice/cb.png), calc(50% - 105px));
          @include buttonsBefore('.pay_by_cb', url(/assets/invoice/cb.png), calc(50% - 105px));
        }
      }
      @include buttonsStyle();
      .buttons {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 31px 0;
        .infos_head {
          position: absolute;
          top: -78px;
          left: 15px;
          width: 100%;
          color: white;
          background-color: transparent;
          font-size: 14px;
          .total {
            font-weight: bold;
          }
        }
        .btn {
          margin-bottom: 20px;
          width: 80%;
          @include buttonsBefore('.pay_by_cb', url(/assets/invoice/cb.png), calc(50% - 90px));
          @include buttonsBefore('.debit', url(/assets/invoice/transfert.png), calc(50% - 97px));
          @include buttonsBefore('.download_invoice', url(/assets/invoice/dl_blue.png), calc(50% - 90px));
          @include buttonsBefore('.visualize_invoice', url(/images/common/open_eye_blue.svg), calc(50% - 90px));
          @include buttonsBefore(".manage_rib", url(/assets/invoice/transfert.png), calc(50% - 75px));
        }
        .help_div {
          font-size: 14px;
          text-align: justify;
          width: 80%;
          color: white;
        }
      }
    }
    .all_invoices {
      padding-left: 16px;
      padding-right: 16px;
      &.payement {
        margin-bottom: 170px;
      }
      .mobile_invoice_view_container {
        background-color: white;
        .infos {
          border-top: $bg-menu 1px solid;
          padding: 8px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 15px;
          margin-bottom: 20px;
          font-size: 12px;
          color: #707070;
          .numbers {
            display: flex;
            flex-wrap: wrap;
            .spacer {
              margin: 0 5px;
            }
            .user_role {
              font-size: 16px;
              color: #666666;
            }
          }
          .address {
            position: relative;
            padding-left: 15px;
            &:before {
              content: "";
              position: absolute;
              left: -5px;
              top: 0;
              bottom: 0;
              width: 20px;
              background: url(/assets/invoice/place.png) (center / contain) no-repeat;
            }
          }
        }
      }
      .mobile_invoice_view {
        &.slip {
          height: 69px;
        }
        display: flex;
        justify-content: space-between;
        padding: 15.15px 13px;
        background-color: white;
        margin-bottom: 10px;
        font-size: 14px;
        position: relative;
        line-height: normal;
        .triangle {
          border-bottom: 9px solid transparent;
          border-right: 9px solid transparent;
          width: 0;
          height: 0;
          position: absolute;
          top: 0;
          left: 0;
          @include invoicesBefore(".unpayed", $red-indicator, 9px);
          @include invoicesBefore(".unpayed_s", $red-indicator, 9px);
          @include invoicesBefore(".payed", $green-indicator, 9px);
          @include invoicesBefore(".pending", $orange-indicator, 9px);
        }
        .col {
          display: flex;
          flex-direction: column;
          .label {
            background-image: $gradient-bright-bg1;
            padding: 5px 0;
            margin-top: 5px;
          }
          &.right {
            text-align: right;
          }
          .details {
            color: #666666;
            &.colored {
              color: $text-title;
              font-size: 16px;
              .tax_sentence{
                font-size: 12px;
              }
            }
            &.italic {
              font-style: italic;
            }
            &.bold {
              font-weight: bold;
            }
          }
        }
      }
    }

    .invoices-display-header-mobile {
      margin-top: 20px;
      &.allContracts {
        position: relative;
      }
      .unpayed_head {
        padding-right: 16px;
        .btn.btn-tertiary.pay {
          width: 111px;
          color: $text-title;
          background-color: white;
          border-radius: 20px;
          padding-right: 0;
          transform: translateY(-20%);
          @include buttonsBefore(".pay", url(/assets/invoice/cb.png), 15px);
          & + .tooltip > .tooltip-arrow {
            border-bottom-color: $text-title;
          }
          & + .tooltip > .tooltip-inner {
            background-color: white;
            border: solid 1px $text-title;
            font-family: $first-font;
            font-size: 16px;
            color: $text-title;
          }
          & + .tooltip {
            opacity: 1.0;
          }
        }
      }
      .title {
        margin-top: 0;
        text-align: unset;
      }

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 15px;
        margin-bottom: 20px;

        .numbers {
          display: flex;
          font-size: 16px;
          color: $text-title;
          line-height: 32px;
          align-items: baseline;
          flex-wrap: wrap;
          .contract_number {
            font-weight: bold;
            .energy_type_ico {
              max-width: 17px;
            }
          }
          .spacer {
            margin: 0 5px;
          }
          .user_role {
            font-size: 16px;
            color: #666666;
          }
        }
        .address {
          font-size: 12px;
          position: relative;
          padding-left: 15px;
          &:before {
            content: "";
            position: absolute;
            left: -5px;
            top: 0;
            bottom: 0;
            width: 20px;
            background: url(/assets/invoice/place.png) (center / contain) no-repeat;
          }
        }
      }


      .buttons {
        display: flex;
        flex-direction: column;
        @include buttonsStyle();
        .btn {
          cursor: pointer;
          margin: 7.5px 15px;
          &.btn-primary {
            @include buttonsBefore(".debit", url(/assets/invoice/transfert_white.png), calc(50% - 100px));
            @include buttonsBefore(".efacture", url(/assets/invoice/efacture.png), calc(50% - 125px));
          }
          &.btn-tertiary {
            @include buttonsBefore(".modify_address", url(/assets/invoice/edit.png), calc(50% - 125px));
            @include buttonsBefore(".modify_post_address", url(/assets/invoice/edit.png), calc(50% - 140px));
            @include buttonsBefore(".manage_rib", url(/assets/invoice/transfert.png), calc(50% - 75px));
          }
        }
      }

      .head {
        color: $text-secondary;
        font-family: $first-font;
        font-size: 18px;
        line-height: normal;
        text-align: left;
        margin-left: 16px;
        margin-bottom: 20px;
      }
    }

    .mobile_contracts_view {
      display: flex;
      flex-direction: column;
      width: 100%;
      &.bottom-sheet-spacer {
        // 70 px is an approx of the max bottom sheet size
        // 10px of outer padding + 20px for the button + 20px of inner padding + 20px of one line of fontsize 16 (might scale up)
        margin-bottom: 125px;
      }
      &.payement {
        margin-bottom: 170px;
      }
      .mobile_contract_view {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        margin: 0 16px;
        margin-bottom: 10px;
        padding: 0 20px;
        height: 74px;
        justify-content: center;
        background-color: white;
        cursor: pointer;
        .contract_content {
          display: flex;
          align-items: baseline;
        }
        .details {
          color: #666666;
          &.bold {
            font-weight: bold;
          }
          .user_role {
            font-size: 14px;
            color: #666666;
            font-weight: normal;
          }
          &.address {
            position: relative;
            padding-left: 15px;
            font-size: 12px;
            &:before {
              content: "";
              position: absolute;
              left: -5px;
              top: 0;
              bottom: 0;
              width: 20px;
              background: url(/assets/invoice/place.png) (center / contain) no-repeat;
            }
          }
          .energy_type_ico {
            max-width: 17px;
          }
        }
      }
    }
    .switch_div {
      @media screen and (max-width: 767px) {
        top: 20px;
        margin: 0;
        position: absolute;
        right: 0px;
      }
      width: 180px;
     
      text-align: center;
      .switch_title {
        color: $text-title;
        line-height: 30px;
      }
      .stopped_contracts_eye {
        height: 16px;
        margin-left: 5px;
      }
    }
    nav.page_list {
      display: flex;
      align-items: center;
      margin: 10px 0;
      justify-content: center;
      .page-item {
        cursor: pointer;
        .page-link {
          color: #666666;
          font-weight: bold;
          &.button_page_\.\.\. {
            cursor: default;
          }
          &.active_page {
            cursor: default;
            background-size: 115% 100%;
            background-image: $gradient-red-medium;
            color: white !important;
          }
        }
      }
      #page_form {
        display: inline-block;
        margin-right: 20px;
        div {
          display: inline-block;
          margin-right: 5px;
          max-width: 130px;
        }
        input {
          max-width: 30px;
          &[type="number"] {
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            -moz-appearance: textfield;
          }
        }
      }
    }
  }
  .modal-body {
    &.big_modal {
      height: 80vh;
      @media screen and (max-width: 767px) {
        height: 70vh;
      }
    }
    .alert {
      display: block;
    }
    input {
      width: 100%;
      line-height: 1.7;
      padding-left: 10px;
    }
    .input-group {
      margin-bottom: 5px;
      margin-top: 5px;
      .input-group-addon {
        min-width: 100px;
        color: $text-quinary;
        background-image: $gradient-bright-bg1;
        border: 1px solid $text-secondary;
      }
    }
    .select_address {
      margin: 15px 0;
      padding: 5px;
      border-radius: 5px;
    }
    .error-p {
      color: $text-primary;
      display: none;
      font-family: $first-font;
      font-size: 12px;
      text-align: left;
      line-height: 14px;
      font-weight: normal;
    }
    .exclamation-circle-icon {
      margin-right: 5px;
      width: 18px;
    }
    .modal_email_input {
      margin-bottom: 10px;
    }
  }

  .modal-content {
    @media screen and (max-width: 767px) {
      margin-top: 50px;
      &.big_content {
        width: 90%!important;
        margin-left: auto;
        margin-right: auto;
      }
      .big_footer {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: space-between;
        height: 160px;;
      }
    }
    &.big_content {
      width: 680px;
    }
    .modal-footer {
      .btn {
        margin: 0;
      }
      .btn-primary {
        color: #fff;
        background-size: 300% 100%;
        background-image: $gradient-red;
        border-radius: 20px;
      }
      .btn-tertiary {
        color: $text-title;
        background-color: $bg-menu;
        border-radius: 20px;
      }
      .fa-spinner {
        margin-left: 5px;
      }
    }
  }

  .loader_container.none {
    display: none;
  }

  .chargingRIB {
    text-align: center;
    font-size: 26px !important;
    top: calc(100% - 10px);
    left: 50%;
    position: absolute;
    z-index: 8;
    transform: translate(-50%, -50%);
  }

  .mobile_navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-image: $gradient-bright-bg;
    box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.15);
    height: 45px;
    @media screen and (min-width: 768px) {
      display: none;
    }
    .nav_arrow {
      height: 30px;
      width: 30px;
      position: absolute;
      left: 15px;
      cursor: pointer;
    }
    .nav_text {
      font-size: 16px;
      color: $text-quinary;
    }
  }

  #invoicePDFPreviewModal {
    @media screen and (max-width: 767px) {
      padding: 0!important;
      overflow-y: hidden;
      .modal-dialog {
        width: 100%;
        height: 100%;
        margin: 0;
        .modal-content {
          width: 100%;
          height: 100%;
          margin: 0;
          border-radius: 0;
          .modal-header {
            padding: 0;
            border: 0;
            .mobile_navbar {
              justify-content: flex-start;
              align-items: flex-end;
              height: 90px;
              .nav_arrow {
                bottom: 20px;
              }
              .nav_text {
                margin-left: 70px;
                margin-bottom: 23px;
              }
            }
          }
          .modal-body {
            height: calc(100% - 90px);
            padding: 0;
            iframe {
              border: 0;
            }
          }
          .modal-footer {
            display: none;
          }
        }
      }
    }
  }
}

#downloadAlert {
  .modal-dialog {
    @media screen and (max-width: 767px) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .modal-content {
    width:100%;
    .modal-body {
      padding: 0;
      .alert {
        display: block;
        font-size: 16px;
        margin-bottom: 0;
      }
      .close {
        opacity: 1;
        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .modal-dialog-centered {
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    min-height:calc(100% - (.5rem * 2));
  }

  @media screen and (min-width: 576px) {
    .modal-dialog-centered {
      min-height:calc(100% - (1.75rem * 2));
    }
  }
}
