/*
 * File: _struct.scss
 * File Created: Friday, 22nd March 2019 8:40:30 am
 * Author: Pierre Bonny (pierre.bonny@gridpocket.com)
 * -----
 * Last Modified: Tuesday, 20th August 2019 5:10:08 pm
 * Modified By: Julien Blacas (julien.blacas@gridpocket.com>)
 * -----
 * GridPocket SAS Copyright (C) 2019 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */
/*
* Base structure
*/

/* Move down content because we have a fixed navbar that is 50px tall + full height background */
/* UPDATE: 26/12/2019 Seems to be mobile view only and for legacy */
@media screen and (max-width: 767px) {
  .body_survey, .body_simu, .body_profile{
    padding-top: 45px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

/*
* Fix for Slicky slider and Flex
*/

* {
  min-width: 0;
  /*min-height: 0;*/
}

.container {
  width: 100% !important;
}

/*
* Content
*/

.body_content {
  display: flex;
  .main {
    background-color: #fff;
    flex: 1;
    width: 100%;
  }
}

.col-centered{
  float: none;
  margin: 0 auto;
}
/*
* Main
*/

/*
* Placeholder dashboard ideas
*/
/*.placeholders { margin-bottom: 30px; text-align: center; }
.placeholders h4 { margin-bottom: 0; }
.placeholder { margin-bottom: 20px; }
.placeholder img { display: inline-block; border-radius: 50%; }
*/
